import SoftBox from 'components/SoftBox';
import { Box } from '@mui/material';
import SoftTypography from 'components/SoftTypography';

export default function App({ tag }) {
	let innercard = (
		<>
			<img
				className="card-status-image"
				src={
					tag === 'Verified Safe'
						? require('../../assets/images/success.png')
						: tag === 'Safe'
						? require('../../assets/images/safe.png')
						: tag === 'Unsafe'
						? require('../../assets/images/unsafe.png')
						: require('../../assets/images/unknown.png')
				}
				alt="tag: "
			/>
			<Box
				sx={{
					width: 'fit-content',
					height: 'fit-content',
					backgroundColor: '#F7F9FB',
					padding: '16px',
					borderRadius: '16px',
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<SoftTypography variant="h5">{tag}</SoftTypography>
				<SoftTypography variant="h6" fontWeight="fontWeightRegular">
					{tag === 'Verified Safe'
						? 'Verified safe is a tag given to smart contracts verified by etherscan and fulfill a set of safety requirements.'
						: tag === 'Safe'
						? 'Safe is a tag given to smart contracts not verified by etherscan but fulfill a set of safety requirements'
						: tag === 'Unsafe'
						? 'Unsafe is a tag given to smart contracts not verified by etherscan and are very new or unsafe to transact with'
						: 'Unknown is a tag given to smart contracts for which safety requirements could not be determined'}
				</SoftTypography>
			</Box>
		</>
	);

	return (
		<SoftBox
			py={4}
			sx={{
				backgroundColor: '#F2F5FA',
				'border-radius': '16px',
				padding: ' 1rem 1.5rem',
				'max-width': '350px',
				height: '100%',
			}}
		>
			<Box
				sx={{
					width: 'fit-content',
					height: 'fit-content',
					backgroundColor: '#F2F5FA',
					padding: '16px',
					borderRadius: '16px',
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<SoftTypography variant="h4">Safety Status</SoftTypography>
				{innercard}
			</Box>
		</SoftBox>
	);
}
