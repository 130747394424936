import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import SoftTypography from 'components/SoftTypography';
import { ethers } from 'ethers';

let data = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];
let sum = 0;
const COLORS = ['#BAEDBD', '#1C1C1C', '#C6C7F8', '#95A4FC'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};
const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
				{`(Rate ${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};
function ClientChart({ method_dist, abiObj }) {
	let signatures = { '0x60806040': 'constructor' };

	if (abiObj !== false) {
		const iface = new ethers.utils.Interface(abiObj);
		console.log('abiobj ', abiObj);
		console.log('signatures', signatures);

		for (let e of abiObj) {
			if (e.type === 'function') {
				signatures[iface.getSighash(e.name)] = e.name;
			}
		}
	}
	data = [];
	for (var key in method_dist) {
		if (method_dist.hasOwnProperty(key)) {
			data.push({
				name: signatures[key],
				value: method_dist[key],
			});
			sum += method_dist[key];
		}
	}

	const [activeIndex, setActiveIndex] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	React.useEffect(() => {
		for (let i = 0; i < data.length; ++i) {
			sum = sum + data[i].value;
		}

		if (window.innerWidth < 600) setIsMobile(true);
	}, window.innerWidth);
	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);
	return (
		<>
			<SoftTypography variant="h4">Traffic by Functions</SoftTypography>
			<div className="secondary-container">
				<ResponsiveContainer width={isMobile ? '60%' : '40%'} height={250}>
					<PieChart>
						<Pie
							activeIndex={activeIndex}
							data={data}
							cx="50%"
							cy="50%"
							labelLine={false}
							outerRadius={85}
							innerRadius={60}
							fill="#8884d8"
							dataKey="value"
							onMouseEnter={onPieEnter}
						>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
				<ul className="ul">
					{data.map((ele, i) => {
						return (
							<li className="chart-detail-data">
								<div className="detail">
									<div className="dot" style={{ background: `${COLORS[i]}` }}></div>
									<SoftTypography variant="h6">{ele.name}</SoftTypography>
								</div>
								<SoftTypography variant="h6">{Number(ele.value)}</SoftTypography>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);
}

export default ClientChart;
