/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Box, Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import Grid from "@mui/material/Grid";
import moment from "moment";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
// Soft UI Dashboard React components
import Icon from "@mui/material/Icon";
import SoftPagination from "components/SoftPagination";
import SoftButton from "components/SoftButton";

function Table({ columns, rows, totalPage, setPageNumber, pageNumber }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ Header, name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={Header}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {Header.toUpperCase()}
      </SoftBox>
    );
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [rowsx, setRows] = useState([]);
  const [checked, setChecked] = useState(["PENDING", "STARTED", "EXECUTED"]);
  const statusArray = [
    { name: "Pending", value: "PENDING" },
    { name: "Started", value: "STARTED" },
    { name: "Executed", value: "EXECUTED" },
  ];
  useEffect(() => {
    let rows_ = [];
    if (rows.length !== 0) {
      for (let i of rows) {
        let row = {};

        if (checked.includes(i.status)) {
          row.name = (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
              <SoftBox mr={2}>
                <Icon>
                  {i.status === "PENDING"
                    ? "pending"
                    : i.status === "STARTED"
                    ? "start"
                    : i.status === "EXECUTED"
                    ? "check_circle"
                    : "error"}
                </Icon>
              </SoftBox>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                  {i.name}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          );
          row.status = (
            <SoftBadge
              variant="gradient"
              badgeContent={i.status}
              color={
                i.status === "PENDING"
                  ? "pending"
                  : i.status === "STARTED"
                  ? "warning"
                  : i.status === "EXECUTED"
                  ? "completed"
                  : "rejected"
              }
              size="xs"
              container
            />
          );
          row.project = (
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color="text"
              >
                {i.project.name}
              </SoftTypography>
            </SoftBox>
          );
          row.expiry = moment(i.expireAt).format("LLL");
          row.method = (
            <Grid container direction="row">
              <Grid px={1}>
                {i.method === "GET" ? (
                  <Icon color="primary">ads_click</Icon>
                ) : (
                  <Icon color="secondary">post_add</Icon>
                )}
              </Grid>
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {i.method}
              </SoftTypography>
            </Grid>
          );
          // row.url = i.url;
          // row.address = i.contract_address;
          rows_.push(row);
        }
      }
    }
    setRows(rows_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsx, checked, rows]);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  function onFilterChange(filter) {
    const select = checked;
    if (select.indexOf(filter) === -1) {
      select.push(filter);
    } else {
      select.splice(select.indexOf(filter), 1);
    }
    setChecked(select);
  }

  const renderRows = rowsx.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;
      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={
              row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
            }
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar
                  src={row[name][0]}
                  name={row[name][1]}
                  variant="rounded"
                  size="sm"
                />
              </SoftBox>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                sx={{ width: "max-content" }}
              >
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={
              row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null
            }
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return (
    <>
      <Box
        px={1}
        p={3}
        pt={2}
        sx={{ zIndex: "tooltip", display: "flex", justifyContent: "end" }}
      >
        <SoftButton
          variant="gradient"
          color="info"
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Filter Status
        </SoftButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 50, paddingTop: "5px" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper elevation={4} sx={{ "border-radius": "10px" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    {statusArray.map((filter, i) => (
                      <MenuItem onClick={handleClose}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => onFilterChange(filter.value)}
                              checked={checked.includes(filter.value)}
                            />
                          }
                          label={filter.name}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
        {totalPage > 1 ? (
          totalPage < 5 ? (
            <SoftBox
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </SoftPagination>
                {[...Array(totalPage)].map((item, index) => (
                  <SoftPagination
                    item
                    active={pageNumber === index + 1 ? true : false}
                    onClick={() => {
                      setPageNumber(index + 1);
                    }}
                  >
                    {index + 1}
                  </SoftPagination>
                ))}
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== totalPage) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </SoftPagination>
              </SoftPagination>
            </SoftBox>
          ) : pageNumber === 1 || pageNumber === 2 ? (
            <SoftBox
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === 1 ? true : false}
                  onClick={() => {
                    setPageNumber(1);
                  }}
                >
                  1
                </SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === 2 ? true : false}
                  onClick={() => {
                    setPageNumber(2);
                  }}
                >
                  2
                </SoftPagination>
                <SoftPagination item>...</SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === totalPage ? true : false}
                  onClick={() => {
                    setPageNumber(totalPage);
                  }}
                >
                  {totalPage}
                </SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== totalPage) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </SoftPagination>
              </SoftPagination>
            </SoftBox>
          ) : pageNumber === totalPage - 1 || pageNumber === totalPage ? (
            <SoftBox
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === 1 ? true : false}
                  onClick={() => {
                    setPageNumber(1);
                  }}
                >
                  1
                </SoftPagination>
                <SoftPagination item>...</SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === totalPage - 1 ? true : false}
                  onClick={() => {
                    setPageNumber(totalPage - 1);
                  }}
                >
                  {totalPage - 1}
                </SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === totalPage ? true : false}
                  onClick={() => {
                    setPageNumber(totalPage);
                  }}
                >
                  {totalPage}
                </SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== totalPage) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </SoftPagination>
              </SoftPagination>
            </SoftBox>
          ) : (
            <SoftBox
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_left</Icon>
                </SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === 1 ? true : false}
                  onClick={() => {
                    setPageNumber(1);
                  }}
                >
                  1
                </SoftPagination>
                <SoftPagination item>...</SoftPagination>
                <SoftPagination item active>
                  {pageNumber}
                </SoftPagination>
                <SoftPagination item>...</SoftPagination>
                <SoftPagination
                  item
                  active={pageNumber === totalPage ? true : false}
                  onClick={() => {
                    setPageNumber(totalPage);
                  }}
                >
                  {totalPage}
                </SoftPagination>
                <SoftPagination
                  item
                  onClick={() => {
                    if (pageNumber !== totalPage) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <Icon>keyboard_arrow_right</Icon>
                </SoftPagination>
              </SoftPagination>
            </SoftBox>
          )
        ) : (
          <></>
        )}
      </TableContainer>
    </>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // [columns, rows]
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
