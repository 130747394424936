/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
// import { useNavigate } from 'react-router-dom';
// import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';

import { getAuthHeader } from 'utils/auth';
import { getProjectsAPI } from 'apis/project';
import { getTransactionsDashboardAPI } from 'apis/transaction';
// import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

// Soft UI Dashboard React base styles
// import typography from 'assets/theme/base/typography';

// Dashboard layout components
//import BuildByDevelopers from 'pages/dashboard/components/BuildByDevelopers';
//import WorkWithTheRockets from 'pages/dashboard/components/WorkWithTheRockets';
import Projects from 'pages/dashboard/components/Projects';
import TransactionOverview from 'pages/dashboard/components/TransactionOverview';
//import ButtonStatisticsCard from './components/ButtonStatisticsCard';
//import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import HistoricalChart from 'components/Charts/HistoricalChart';
//import SoftPagination from "components/SoftPagination";
import { Card } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import { getProjectsTableAPI } from 'apis/project';
//import SoftButton from "components/SoftButton";
//import SyncIcon from "@mui/icons-material/Sync";
import { getHistoricalTransactionsAnalyticsAPI } from 'apis/analytics';

//import SoftPagination from 'components/SoftPagination';
// import SoftTypography from 'components/SoftTypography';

// Data
// import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
// import gradientLineChartData from 'layouts/dashboard/data/gradientLineChartData';

function Dashboard() {
	// const { size } = typography;
	// const { chart, items } = reportsBarChartData;
	// const navigate = useNavigate();
	const navigate = useNavigate();

	const [projects, setProjects] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [data, setData] = useState([]);

	const [pageNumber, setPageNumber] = useState(1);

	const [totalPage, setTotalPage] = useState(1);

	const [historicalData, SetHistoricalData] = useState([]);

	const fetchProjects = async () => {
		let data = await getProjectsAPI();
		if (data.status === 200) {
			setProjects(data.data.results);
		} else {
			setProjects([]);
		}
	};

	const fetchHistoricalData = async (length) => {
		let data = await getHistoricalTransactionsAnalyticsAPI(length);
		if (data.status === 200) {
			let hisArr = [];
			const keys = Object.keys(data.data);
			for (let e of keys.reverse()) {
				hisArr.push({
					name: e,
					uv: data.data[e],
				});
			}
			SetHistoricalData(hisArr);
		} else {
			SetHistoricalData([]);
		}
		// setProjects(data.data.results);
	};

	const fetchTransactions = async () => {
		let data = await getTransactionsDashboardAPI();
		if (data.status === 200) {
			setTransactions(data.data.results);
		} else {
			setTransactions([]);
		}
	};

	const fetchProjectsTable = async () => {
		let data = await getProjectsTableAPI(pageNumber);
		if (data.status === 200) {
			setData(data.data.results);
			setTotalPage(data.data.totalPages);
		} else {
			setData([]);
			setTotalPage(1);
		}
	};

	useEffect(() => {
		fetchProjects();
		fetchTransactions();
		fetchHistoricalData(7);
	}, []);

	useEffect(() => {
		fetchProjectsTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	function RefreshAction() {
		setPageNumber(1);
		fetchProjectsTable();
	}

	useEffect(() => {
		let token = getAuthHeader();
		if (!token) {
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox>
				<SoftTypography variant="h3">Dashboard</SoftTypography>
			</SoftBox>
			<SoftBox py={3}>
				<SoftBox mb={3}>
					<Grid container spacing={3}>
						{/* <Grid item xs={12} sm={6} xl={3}>
							<ButtonStatisticsCard
								text="New Project"
								// percentage={{ color: 'success', text: '+55%' }}
								icon={{ color: 'info', component: 'create' }}
							/>
						</Grid> */}
						<Grid item xs={12} sm={6} xl={4}>
							<MiniStatisticsCard
								title={{ text: 'Total Number of Project' }}
								count={projects.length}
								// percentage={{ color: 'success', text: '+55%' }}
								icon={{ color: 'info', component: 'account_tree' }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} xl={4}>
							<MiniStatisticsCard
								title={{ text: 'Total Transactions' }}
								count={transactions.length}
								// percentage={{ color: 'success', text: '+55%' }}
								icon={{ color: 'info', component: 'credit_card' }}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={6} xl={4}>
							<MiniStatisticsCard
								title={{ text: 'Health States' }}
								count={transactions.length}
								// percentage={{ color: 'success', text: '+3%' }}
								icon={{ color: 'info', component: 'public' }}
							/>
						</Grid> */}
						{/* <Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'new clients' }}
								count="+3,462"
								// percentage={{ color: 'error', text: '-2%' }}
								icon={{ color: 'info', component: 'emoji_events' }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'sales' }}
								count="$103,430"
								percentage={{ color: 'success', text: '+5%' }}
								icon={{
									color: 'info',
									component: 'shopping_cart',
								}}
							/>
						</Grid> */}
					</Grid>
				</SoftBox>
				{/* <SoftBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={7}>
							<BuildByDevelopers />
						</Grid>
						<Grid item xs={12} lg={5}>
							<WorkWithTheRockets />
						</Grid>
					</Grid>
				</SoftBox> */}

				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12}>
						<Projects projects={data} totalPage={totalPage} pageNumber={pageNumber} setPageNumber={setPageNumber} />
					</Grid>
					<Grid item xs={12} md={6} lg={8}>
						<SoftBox>
							<Card style={{ boxShadow: 'none' }}>
								<SoftBox p={3} pt={4}>
									<SoftTypography variant="h5" mb={3.5} pl={2}>
										Historical Values
									</SoftTypography>
									<HistoricalChart data={historicalData} />
								</SoftBox>
							</Card>
						</SoftBox>
						{/* <SoftPagination>
              <SoftPagination item>
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              <SoftPagination item active>
                1
              </SoftPagination>
              <SoftPagination item>2</SoftPagination>
              <SoftPagination item>3</SoftPagination>
              <SoftPagination item>
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination> */}
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<TransactionOverview transactions={transactions} totalPage={1} RefreshAction={RefreshAction} />
					</Grid>
				</Grid>
				{/* <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={6} lg={6}>
            {/* <DefaultLineChart
              title="Historical Values"
              chart={{
                labels: [
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "Direct",
                    color: "primary",
                    data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
                  },
                ],
              }}
            /> 
            <div className="card-background-container">
              <h4 className=""> Historical Values </h4>
              <HistoricalChart />
            </div>
          </Grid>
        </Grid> */}
			</SoftBox>
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default Dashboard;
