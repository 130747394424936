/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//import { useState, useEffect } from 'react';

// @mui material components
import Card from "@mui/material/Card";
//import Icon from '@mui/material/Icon';
//import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import moment from "moment";
import Icon from "@mui/material/Icon";
import SoftPagination from "components/SoftPagination";
import SyncIcon from "@mui/icons-material/Sync";
import SoftButton from "components/SoftButton";
//import projectsTableData from 'pages/transactions/data/projectsTableData';

function TransactionsOverview({
  transactions,
  totalPage,
  setPageNumber,
  pageNumber,
  RefreshAction,
}) {
  return (
    <Card className="h-100" style={{ boxShadow: "none" }}>
      <SoftBox
        pt={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        //  flexWrap="wrap"
        gap="10px"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Transactions Overview
        </SoftTypography>
        <SoftBox>
          {/* <button
            className="refresh-button"
            onClick={() => {
              RefreshAction();
            }}
          >
            <SyncIcon />
            Refresh
          </button> */}
          <SoftButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              RefreshAction();
            }}
          >
            <SyncIcon />
            Refresh
          </SoftButton>
        </SoftBox>
        {/* <SoftBox mt={1} mb={2}>
					<SoftTypography variant="button" color="text" fontWeight="regular">
						<SoftTypography display="inline" variant="body2" verticalAlign="middle">
							<Icon sx={{ fontWeight: 'bold', color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
						</SoftTypography>
						&nbsp;
						<SoftTypography variant="button" color="text" fontWeight="medium">
							24%
						</SoftTypography>{' '}
						this month
					</SoftTypography>
				</SoftBox> */}
      </SoftBox>
      <SoftBox p={2}>
        {transactions.map((transaction) => {
          return (
            <TimelineItem
              color={
                transaction.status === "PENDING"
                  ? "info"
                  : transaction.status === "STARTED"
                  ? "warning"
                  : transaction.status === "EXECUTED"
                  ? "success"
                  : "error"
              }
              icon={
                transaction.status === "PENDING"
                  ? "pending"
                  : transaction.status === "STARTED"
                  ? "start"
                  : transaction.status === "EXECUTED"
                  ? "check_circle"
                  : "error"
              }
              name={transaction.project.name}
              title={transaction.name}
              //  dateTime={new Date(transaction.expireAt).toDateString()}
              dateTime={moment(transaction.expireAt).format("LLL")}
              status={transaction.status}
            />
          );
        })}
      </SoftBox>
      {totalPage > 1 ? (
        totalPage < 5 ? (
          <SoftBox
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              {[...Array(totalPage)].map((item, index) => (
                <SoftPagination
                  item
                  active={pageNumber === index + 1 ? true : false}
                  onClick={() => {
                    setPageNumber(index + 1);
                  }}
                >
                  {index + 1}
                </SoftPagination>
              ))}
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== totalPage) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination>
          </SoftBox>
        ) : pageNumber === 1 || pageNumber === 2 ? (
          <SoftBox
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              <SoftPagination
                item
                active={pageNumber === 1 ? true : false}
                onClick={() => {
                  setPageNumber(1);
                }}
              >
                1
              </SoftPagination>
              <SoftPagination
                item
                active={pageNumber === 2 ? true : false}
                onClick={() => {
                  setPageNumber(2);
                }}
              >
                2
              </SoftPagination>
              <SoftPagination item>...</SoftPagination>
              <SoftPagination
                item
                active={pageNumber === totalPage ? true : false}
                onClick={() => {
                  setPageNumber(totalPage);
                }}
              >
                {totalPage}
              </SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== totalPage) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination>
          </SoftBox>
        ) : pageNumber === totalPage - 1 || pageNumber === totalPage ? (
          <SoftBox
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              <SoftPagination
                item
                active={pageNumber === 1 ? true : false}
                onClick={() => {
                  setPageNumber(1);
                }}
              >
                1
              </SoftPagination>
              <SoftPagination item>...</SoftPagination>
              <SoftPagination
                item
                active={pageNumber === totalPage - 1 ? true : false}
                onClick={() => {
                  setPageNumber(totalPage - 1);
                }}
              >
                {totalPage - 1}
              </SoftPagination>
              <SoftPagination
                item
                active={pageNumber === totalPage ? true : false}
                onClick={() => {
                  setPageNumber(totalPage);
                }}
              >
                {totalPage}
              </SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== totalPage) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination>
          </SoftBox>
        ) : (
          <SoftBox
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              <SoftPagination
                item
                active={pageNumber === 1 ? true : false}
                onClick={() => {
                  setPageNumber(1);
                }}
              >
                1
              </SoftPagination>
              <SoftPagination item>...</SoftPagination>
              <SoftPagination item active>
                {pageNumber}
              </SoftPagination>
              <SoftPagination item>...</SoftPagination>
              <SoftPagination
                item
                active={pageNumber === totalPage ? true : false}
                onClick={() => {
                  setPageNumber(totalPage);
                }}
              >
                {totalPage}
              </SoftPagination>
              <SoftPagination
                item
                onClick={() => {
                  if (pageNumber !== totalPage) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination>
          </SoftBox>
        )
      ) : (
        <></>
      )}
      {/* <SoftBox p={2}>
        <TimelineItem
          color="success"
          icon="notifications"
          title="$2400, Design changes"
          dateTime="22 DEC 7:20 PM"
          status="success"
          name="Design changes"
        />
        <TimelineItem
          color="error"
          icon="inventory_2"
          title="New order #1832412"
          dateTime="21 DEC 11 PM"
        />
        <TimelineItem
          color="info"
          icon="shopping_cart"
          title="Server payments for April"
          dateTime="21 DEC 9:34 PM"
        />
        <TimelineItem
          color="warning"
          icon="payment"
          title="New card added for order #4395133"
          dateTime="20 DEC 2:20 AM"
        />
        <TimelineItem
          color="primary"
          icon="vpn_key"
          title="New card added for order #4395133"
          dateTime="18 DEC 4:54 AM"
        />
        <TimelineItem
          color="dark"
          icon="paid"
          title="New order #9583120"
          dateTime="17 DEC"
        />
      </SoftBox> */}
    </Card>
  );
}

export default TransactionsOverview;
