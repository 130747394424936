import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useSigner,
} from "wagmi";
import { ethers } from "ethers";
import ABI from "./a.json";

// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import ChartClients from "./chart";

import "./client.css";

import { useParams } from "react-router-dom";

import { getTx4ExecAPI } from "apis/execute";

function Demo() {
  let { id } = useParams();
  const [txInfo, setTxInfo] = useState([]);

  const fetchTxInfo = async () => {
    let data = await getTx4ExecAPI(id);
    setTxInfo(data.data);
  };

  useEffect(() => {
    fetchTxInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: signer, isError, isLoading } = useSigner();

  // console.log("hahs", typeof signer._address);

  const contract = useContractRead({
    address: "0x8AB8E250c4AfEC2223A4eA9c886091895f3E4246",
    abi: ABI,
    functionName: "name",
  });

  console.log(contract);

  const { config } = usePrepareContractWrite({
    address: "0x8AB8E250c4AfEC2223A4eA9c886091895f3E4246",
    abi: ABI,
    functionName: "changeName",
    args: ["Harsh"],
  });

  const {
    data: data2,
    isLoading: isLoading2,
    isSuccess,
    write,
  } = useContractWrite(config);

  // const conr = useContractWrite({
  //   mode: "recklesslyUnprepared",
  //   address: "0x8AB8E250c4AfEC2223A4eA9c886091895f3E4246",
  //   abi: ABI,
  //   functionName: "receiveNative",
  //   overrides: {
  //     from: "0xEA2d168D845434c2F3F59eDF79F7A8Ae17A779D6",
  //     value: ethers.utils.parseEther("0.01"),
  //   },
  // });

  // const {
  //   data: data1,
  //   isLoading: isLoading1,
  //   isSuccess: isSuccess1,
  //   write: write1,
  // } = useContractWrite(config2);

  // console.log(conr);

  return (
    <SoftBox py={4} px={5}>
      <SoftBox style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Hello</div>
        <ConnectButton />
      </SoftBox>
      <Grid>
        <Grid container direction="row" spacing={3} py={8}>
          <Grid item xs={12} sm={6} xl={4}>
            <div className="card-client">
              <h4>Transaction Details</h4>
              <SoftBox py={4}>
                <p>
                  Contract Address: <span>0xx1232</span>
                </p>
                <p>
                  Owner Name: <span>Harsh</span>
                </p>
                <p>
                  Owner Address: <span>0xw312313</span>
                </p>
                <p>
                  Function Name: <span>ChangeName</span>
                </p>
                <p>
                  Input parameters: <span>aas</span>
                </p>
                <p>
                  Payment Method: <span>No</span>
                </p>
              </SoftBox>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} xl={4}>
            <div className="card-client">
              <ChartClients />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} xl={4}>
            <div className="card-client">
              <h4>Transaction Health</h4>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <SoftButton color={"success"} onClick={() => write?.()}>
        Button
      </SoftButton>

      {isLoading2 && <div>Check Wallet</div>}
      {isSuccess && <div>Transaction: {JSON.stringify(data2)}</div>}
      {/* <button disabled={!write} onClick={() => conr.write?.()}>
        Haha
      </button>
      {conr.isLoading && <div>Check Wallet</div>}
      {conr.isSuccess && <div>Transaction: {JSON.stringify(conr.data)}</div>} */}
    </SoftBox>
  );
}

export default Demo;
