import axios from 'axios';
// // import { toast } from 'react-toastify';
// import { getAuthHeader } from 'utils/auth';

export const getTx4ExecAPI = async (id) => {
	// let token = await getAuthHeader();

	const result = await axios
		.get(process.env.REACT_APP_API_URL + '/v1/execute/' + id)
		.then((res) => {
			return res;
		})
		.catch(function (error) {
			return false;
		});

	return result;
};

export const getHealth4Tx = async (data) => {
	// let token = await getAuthHeader();

	const result = await axios
		.post(process.env.REACT_APP_HEALTH_API_URL + '/transaction/', data, {
			// headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => {
			return res.data;
		})
		.catch(function (error) {
			return error;
		});

	return result;
};
