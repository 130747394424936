/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from 'react';

// react-router-dom components
// import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard React components
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import SoftInput from 'components/SoftInput';
// import SoftButton from 'components/SoftButton';

// Authentication layout components
import CoverLayout from 'pages/landing/components/CoverLayout';

// import Footer from 'pages/landing/components/Footer';

// Images
import curved9 from 'assets/images/curved-images/landing-page.jpg';

import { getAuthHeader } from 'utils/auth';

function Landing() {
	const token = getAuthHeader();
	return (
		<>
			<CoverLayout
				title="Enter the world of Web3!"
				description="Riff provides powerful APIS to execute smart contract calls using REST API calls."
				information="We are building a SaaS utility platform that lets Web3 developers secure and integrate smart contract calls in
				their applications using a REST API-like interface via the use of magic links."
				image={curved9}
				token={token}
			></CoverLayout>

			{/* <SoftBox>
				
			</SoftBox> */}
		</>
	);
}

export default Landing;
