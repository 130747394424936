import SoftBox from 'components/SoftBox';
import React, { useState, useEffect } from 'react';
import { getTx4ExecAPI } from 'apis/execute';
import { useParams } from 'react-router-dom';
import SoftTypography from 'components/SoftTypography';
import ReactJson from 'react-json-view';

export default function App() {
	let { id } = useParams();
	const [txInfo, setTxInfo] = useState([]);

	const limit = (string, length, end = '...') => {
		return string?.length < length ? string : string?.substring(0, length) + end;
	};
	const fetchTxInfo = async () => {
		let data = await getTx4ExecAPI(id);
		setTxInfo(data.data);
	};

	useEffect(() => {
		fetchTxInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<SoftBox
			py={4}
			sx={{
				backgroundColor: '#F2F5FA',
				'border-radius': '16px',
				padding: ' 1rem 1.5rem',
				'max-width': '350px',
				height: '100%',
			}}
		>
			<SoftTypography variant="h4">Transaction Details</SoftTypography>
			<SoftBox py={4}>
				<SoftTypography variant="h6">
					Contract Address:
					<span>{limit(txInfo?.project?.contract_address, 7)}</span>
				</SoftTypography>
				<SoftTypography variant="h6">
					Project Name: <span>{txInfo?.project?.name}</span>
				</SoftTypography>
				{/* <p>Owner Address: <span>0xw312313</span></p> */}
				<SoftTypography variant="h6">
					Function Name: <span>{txInfo?.name}</span>
				</SoftTypography>
				<SoftTypography variant="h6">
					Input parameters: <ReactJson src={txInfo?.inputs} name="Function Input" collapsed={true} />
				</SoftTypography>
				<SoftTypography variant="h6">
					Payment Method: <span>{txInfo?.stateMutability === 'payable' ? 'Yes' : 'No'}</span>
				</SoftTypography>
			</SoftBox>
		</SoftBox>
	);
}
