import SoftBox from 'components/SoftBox';
import { Button } from '@mui/material';
import SoftTypography from 'components/SoftTypography';

export default function App(props) {
	return (
		<SoftBox
			py={4}
			sx={{
				display: 'flex',
				'flex-direction': 'column',
				gap: '16px',
				border: '2px solid black',
				backgroundColor: '#F7F7F7',
				'border-radius': '16px',
				padding: ' 1rem 1.5rem',
				'max-width': '350px',
				height: '100%',
			}}
		>
			<SoftTypography variant="h4">Precise usage warning</SoftTypography>
			<SoftTypography variant="h6">
				You’re about to interact with a smart contract which writes data on the blockchain. This data is immutable and you
				may inccur some fees for this transaction. Make sure you have inspected the inputs!
			</SoftTypography>
			{props.button}
			{/* <Button
        sx={{
          background: " #95A4FC",
          "border-radius": "8px",
          color: "#FFFFFF",
          padding: "4px 8px",
          "align-self": "flex-start",
        }}
        variant="contained"
      >
        Send Transaction
      </Button> */}
		</SoftBox>
	);
}
