import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Line,
  LineChart,
} from "recharts";

const _data_example = [
  { name: "Page A", uv: 400, pv: 400 },
  { name: "Page B", uv: 800, pv: 810 },
  { name: "Page C", uv: 650, pv: 700 },
  { name: "Page D", uv: 200, pv: 190 },
  { name: "Page E", uv: 10, pv: 200 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{}}>
        <p className="label"> Page Name: {label}</p>
        <p className="label"> Value: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

function VariableLineChart({ data, height }) {
  console.log("data is ", data);
  if (data === undefined) {
    data = _data_example;
  } else {
    data = _data_example;
  }
  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="0 0" />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default VariableLineChart;
