/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { useParams, useNavigate } from 'react-router-dom';
// import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { getHealth4Tx } from 'apis/execute';
//import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

// Soft UI Dashboard React base styles
// import typography from 'assets/theme/base/typography';

// Dashboard layout components
//import BuildByDevelopers from "pages/dashboard/components/BuildByDevelopers";
// import WorkWithTheRockets from 'pages/dashboard/components/WorkWithTheRockets';
import Magic from 'pages/projects/project/components/Magic';
import TransactionOverview from 'pages/dashboard/components/TransactionOverview';
//import ButtonStatisticsCard from "./components/ButtonStatisticsCard";
import SoftTypography from 'components/SoftTypography';

import { getProjectAPI, getProjectMagicAPI } from 'apis/project';
import { getTransactionsProjectIdAPI } from 'apis/transaction';
import SyncIcon from '@mui/icons-material/Sync';
import { useEffect, useState } from 'react';
import SoftButton from 'components/SoftButton';
import AnalyticsPieChart from 'components/Charts/AnalyticsPieChart';
import { getProjectFunctionsAnalyticsAPI } from 'apis/analytics';

import SafetyStatus from '../../execute/safetyStatus';
import BarChart from '../../execute/barChart';
import ChartClients from '../../execute/chart';

// Data
// import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
// import gradientLineChartData from 'layouts/dashboard/data/gradientLineChartData';

function Dashboard() {
	// const { size } = typography;
	// const { chart, items } = reportsBarChartData;
	let { id } = useParams();
	const [project, setProject] = useState({});
	const [transactions, setTransactions] = useState([]);
	const [magic, setMagic] = useState([]);
	const [projectFunctions, SetProjectFunctions] = useState([]);
	const [projectFunctionsValue, SetProjectFunctionsVal] = useState(0);
	const [totalPage, setTotalPage] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [analytics, setAnalytics] = useState(false);
	const [healthInfo, setHealthInfo] = useState([]);

	const navigate = useNavigate();

	const fetchProject = async () => {
		let data = await getProjectAPI(id);
		if (data.status !== 200) {
			alert('project not found');
			navigate('/dashboard');
		} else {
			setProject(data.data);
		}
	};

	const fetchTransactions = async () => {
		let data = await getTransactionsProjectIdAPI(id, pageNumber);
		if (data.status === 200) {
			setTotalPage(data.data.totalPages);
			setTransactions(data.data.results);
		}
	};

	const fetchMagic = async () => {
		let data = await getProjectMagicAPI(id);
		if (data.status !== 200) {
			alert('project not found');
			navigate('/dashboard');
		} else {
			setMagic(data.data);
		}
	};

	const fetchHealthInfo = async (txInfo) => {
		let data = await getHealth4Tx({
			networkId: project.network.Id,
			networkName: project.network.name,
			contractAddress: project.contract_address,
			method: '',
			stateMutability: '',
		});
		console.log('health', data);
		setHealthInfo(data.data[0]);
	};

	const fetchProjectFunctions = async () => {
		let data = await getProjectFunctionsAnalyticsAPI(id);

		if (data.status === 200) {
			let pieChartData = [];
			for (let i = 0; i < 3; i++) {
				pieChartData.push({
					name: data.data.txns[i].functionName,
					value: data.data.txns[i].count,
				});
			}
			pieChartData.push({
				name: 'Others',
				value: data.data.otherCount,
			});

			SetProjectFunctions(pieChartData);
			SetProjectFunctionsVal(data.data.totalCount);
			// for (){
			//   pieChartData.push
			// }
			// setProjects(data.data.results);
		}
	};

	function RefreshAction() {
		setPageNumber(1);
		fetchTransactions();
	}

	useEffect(() => {
		fetchTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		fetchProject();
		fetchMagic();
		fetchTransactions();
		fetchProjectFunctions();
		fetchHealthInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchHealthInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [analytics]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox display="flex" justifyContent="space-between" alignItems="end" gap="10px">
					<SoftTypography variant="h3">{project?.name}</SoftTypography>
					{analytics ? (
						<SoftButton
							variant="outlined"
							color="secondary"
							onClick={() => {
								setAnalytics(false);
							}}
						>
							Hide Contract Analytics
						</SoftButton>
					) : (
						<SoftButton
							variant="outlined"
							color="secondary"
							onClick={() => {
								setAnalytics(true);
							}}
						>
							Show Contract Analytics
						</SoftButton>
					)}
				</SoftBox>
			</SoftBox>
			<SoftBox>
				{analytics ? (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={4}>
								<SafetyStatus tag={healthInfo?.tag} />
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<SoftBox
									sx={{
										backgroundColor: '#F2F5FA',
										'border-radius': '16px',
										padding: ' 1rem 1.5rem',
										'max-width': '350px',
										height: '100%',
									}}
								>
									<BarChart />
								</SoftBox>
							</Grid>
							<Grid item xs={12} md={12} lg={8}>
								<SoftBox
									style={{
										'border-radius': '16px',
										width: '100%',
									}}
								>
									<div className="card-client">
										<ChartClients
											method_dist={healthInfo?.method_dist}
											abiObj={project?.contract_object === undefined ? false : project?.contract_object}
										/>
									</div>
								</SoftBox>
							</Grid>
						</Grid>
					</>
				) : (
					<></>
				)}
				<Grid container spacing={3} mt={0}>
					<Grid item xs={12} md={6} lg={6}>
						<Grid
							item
							xs={12}
							md={12}
							lg={12}
							display="flex"
							flexDirection={'column'}
							sx={{ height: '100%', justifyContent: 'space-between' }}
						>
							<Card p={3} style={{ boxShadow: 'none' }}>
								<SoftBox p={3}>
									<SoftTypography variant="h6">Description</SoftTypography>
									<SoftTypography variant="body2">{project?.description}</SoftTypography>
								</SoftBox>
							</Card>
							<SoftBox mt={3}>
								<Card style={{ boxShadow: 'none' }}>
									<SoftBox p={3}>
										<SoftTypography variant="h4" mb={3}>
											API Usage Info
										</SoftTypography>
										<AnalyticsPieChart data={projectFunctions} value={projectFunctionsValue} />
									</SoftBox>
								</Card>
							</SoftBox>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6} lg={6}>
						<TransactionOverview
							transactions={transactions}
							totalPage={totalPage}
							setPageNumber={setPageNumber}
							pageNumber={pageNumber}
							RefreshAction={RefreshAction}
						/>
					</Grid>
				</Grid>
			</SoftBox>
			<SoftBox py={3}>
				<SoftBox mb={3}>
					<Grid container spacing={3}>
						{/* <Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'Total Projects Created' }}
								count="5"
								// percentage={{ color: 'success', text: '+55%' }}
								icon={{ color: 'info', component: 'emoji_events' }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'Total Transactions Created' }}
								count="230"
								// percentage={{ color: 'success', text: '+3%' }}
								icon={{ color: 'info', component: 'public' }}
							/>
						</Grid> */}
						{/* <Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'new clients' }}
								count="+3,462"
								// percentage={{ color: 'error', text: '-2%' }}
								icon={{ color: 'info', component: 'emoji_events' }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} xl={3}>
							<MiniStatisticsCard
								title={{ text: 'sales' }}
								count="$103,430"
								percentage={{ color: 'success', text: '+5%' }}
								icon={{
									color: 'info',
									component: 'shopping_cart',
								}}
							/>
						</Grid> */}
					</Grid>
				</SoftBox>
				{/* <SoftBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={7}>
							<BuildByDevelopers />
						</Grid>
						<Grid item xs={12} lg={5}>
							<WorkWithTheRockets />
						</Grid>
					</Grid>
				</SoftBox> */}
				<SoftBox mb={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={5}>
							{/* <ReportsBarChart
								title="active users"
								description={
									<>
										(<strong>+23%</strong>) than last week
									</>
								}
								// chart={chart}
								// items={items}
							/> */}
						</Grid>
						<Grid item xs={12} lg={7}>
							{/* <GradientLineChart
								title="Sales Overview"
								description={
									<SoftBox display="flex" alignItems="center">
										<SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
											<Icon className="font-bold">arrow_upward</Icon>
										</SoftBox>
										<SoftTypography variant="button" color="text" fontWeight="medium">
											4% more{' '}
											<SoftTypography variant="button" color="text" fontWeight="regular">
												in 2021
											</SoftTypography>
										</SoftTypography>
									</SoftBox>
								}
								height="20.25rem"
								// chart={gradientLineChartData}
							/> */}
						</Grid>
					</Grid>
				</SoftBox>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12}>
						<Magic magic={magic} projectName={project.name} />
					</Grid>
				</Grid>
			</SoftBox>
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default Dashboard;
