import axios from "axios";
// import { toast } from 'react-toastify';
// import { getAuthHeader } from '../../redux/services/authHeader';
// import { errorToast, ServerError, SystemError } from '../../utils/utils';

export const loginAPI = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/v1/auth/login", data)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });

  return result;
};

export const registerAPI = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/v1/auth/register", data)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });

  return result;
};
