export const postManConvert = function (magicList, projectName) {
	const name = projectName;

	const postmanColobj = {
		info: {
			_postman_id: '1dddc8b6-3ab2-409c-80b3-920ad9cb3f99',
			name,
			schema: 'https://schema.getpostman.com/json/collection/v2.1.0/collection.json',
			_exporter_id: '10728340',
		},
		auth: {
			type: 'bearer',
			bearer: [
				{
					key: 'token',
					value: 'YOUR-API-KEY',
					type: 'string',
				},
			],
		},
		event: [
			{
				listen: 'prerequest',
				script: {
					type: 'text/javascript',
					exec: [''],
				},
			},
			{
				listen: 'test',
				script: {
					type: 'text/javascript',
					exec: [''],
				},
			},
		],
	};

	const item = [];

	for (const e of magicList) {
		if (e.method === 'GET') {
			const arr = e.url.split('/');
			let inputStr = '?';
			const query = [];
			// let count = 0;
			for (const inElem of e.inputs) {
				console.log(inElem);
				inputStr = `${inputStr + inElem.name}=${inElem.name}&`;
				query.push({
					key: inElem.name,
					value: inElem.name,
				});
			}
			arr.shift();
			item.push({
				name: e.url.split('/').at(-1),
				request: {
					method: 'GET',
					header: [],
					url: {
						raw: `https://api.getriff.xyz${e.url}${inputStr}`,
						protocol: 'https',
						host: ['api', 'getriff', 'xyz'],
						path: arr,
						query,
					},
				},
				response: [],
			});
		} else if (e.method === 'POST') {
			let raw = '{\r\n';
			let count = 0;
			for (const inElem of e.inputs) {
				count += 1;
				console.log(inElem);

				if (count !== e.inputs.length) {
					raw = `${raw}"${inElem.name}":"${inElem.type}",\r\n`;
				} else if (count === e.inputs.length && e.payable === true) {
					raw = `${raw}"${inElem.name}":"${inElem.type}",\r\n`;
				} else {
					raw = `${raw}"${inElem.name}":"${inElem.type}"\r\n`;
				}
			}
			console.log(raw);
			if (e.payable === true) {
				const pay = 'pay';

				raw = `${raw}"${pay}":0\r\n`;
			}
			raw += '}';
			const arr = e.url.split('/');
			arr.shift();
			item.push({
				name: e.url.split('/').at(-1),
				request: {
					method: 'POST',
					header: [],
					body: {
						mode: 'raw',
						raw,
						options: {
							raw: {
								language: 'json',
							},
						},
					},
					url: {
						raw: `https://api.getriff.xyz${e.url}`,
						protocol: 'https',
						host: ['api', 'getriff', 'xyz'],
						path: arr,
					},
				},
				response: [],
			});
		}
	}

	postmanColobj.item = item;

	return postmanColobj;
};
