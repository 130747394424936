import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
//import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "pages/authentication/components/BasicLayout";
// import Socials from 'pages/authentication/components/Socials';
// import Separator from 'pages/authentication/components/Separator';

import { registerAPI } from "apis/auth";

// Images
import curved6 from "assets/images/curved-images/landing-page.jpg";
import { useDispatch } from "react-redux";
import { ACCESS_TOKEN } from "redux/action/index.action";
import { REFRESH_TOKEN } from "redux/action/index.action";
import { ACCESS_TOKEN_EXPIRES } from "redux/action/index.action";
import { REFRESH_TOKEN_EXPIRES } from "redux/action/index.action";
import { USER_ID } from "redux/action/index.action";
import { USER_NAME } from "redux/action/index.action";
import { errorToast } from "utils/utils";
import { getAuthHeader } from "utils/auth";
//import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
//import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function ForgotPassword() {
  // const [agreement, setAgremment] = useState(true);

  //const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  // const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();

  const register = async () => {
    setCheck(true);
    if (email.length > 0) {
      let data = await registerAPI({ email });
      if (data?.status === 201) {
        setCheck(false);
        dispatch({
          type: ACCESS_TOKEN,
          payload: data.data.tokens.access.token,
        });
        dispatch({
          type: ACCESS_TOKEN_EXPIRES,
          payload: data.data.tokens.access.expires,
        });
        dispatch({
          type: REFRESH_TOKEN,
          payload: data.data.tokens.refresh.token,
        });
        dispatch({
          type: REFRESH_TOKEN_EXPIRES,
          payload: data.data.tokens.refresh.expires,
        });
        dispatch({ type: USER_ID, payload: data.data.user.id });
        dispatch({ type: USER_NAME, payload: data.data.user.name });

        // localStorage.setItem('access-token', data.data.tokens.access.token);
        // localStorage.setItem('access-token-expires', data.data.tokens.access.expires);
        // localStorage.setItem('refresh-token', data.data.tokens.refresh.token);
        // localStorage.setItem('refresh-token-expires', data.data.tokens.refresh.expires);
        // localStorage.setItem('user-id', data.data.user.id);
        // localStorage.setItem('user-name', data.data.user.name);
        navigate("/dashboard");
      } else {
        errorToast(data.response.data.message);
        //show toasty for error;
      }
    }
  };

  useEffect(() => {
    let check = getAuthHeader();

    if (check) {
      window.location.href = "/dashboard";
    }
  }, []);

  // const handleSetAgremment = () => setAgremment(!agreement);

  return (
    <BasicLayout title="Forgot Password!" description="" image={curved6}>
      <Card style={{ boxShadow: "none" }}>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Forgot Password
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
					<Socials />
				</SoftBox>
				<Separator /> */}
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={
                  email.length > 0 || check
                    ? String(email)
                        .toLowerCase()
                        .match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                      ? false
                      : true
                    : false
                }
              />
              {email.length > 0 || check ? (
                String(email)
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) ? (
                  <></>
                ) : (
                  <SoftTypography variant="caption" color="error">
                    Please add a valid Email
                  </SoftTypography>
                )
              ) : (
                <></>
              )}
            </SoftBox>
            {/* <SoftBox display="flex" alignItems="center">
							<Checkbox checked={agreement} onChange={handleSetAgremment} />
							<SoftTypography
								variant="button"
								fontWeight="regular"
								onClick={handleSetAgremment}
								sx={{ cursor: 'poiner', userSelect: 'none' }}
							>
								&nbsp;&nbsp;I agree the&nbsp;
							</SoftTypography>
							<SoftTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
								Terms and Conditions
							</SoftTypography>
						</SoftBox> */}
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={register}
              >
                Send Email
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
