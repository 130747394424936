import { store } from "redux/store/store";

export function getAuthHeader() {
  const expires = store.getState().authRef.accessTokenExpires;

  // let expires = localStorage.getItem('access-token-expires');
  let expiresDate = new Date(expires).getTime();

  let rightnow = Date.now();

  if (rightnow > expiresDate) {
    localStorage.clear();
    window.location.reload();
    return false;
  } else if (store?.getState()?.authRef?.accessTokenExpires?.length > 0) {
    console.log("here", expires);
    return store.getState().authRef.accessToken;
  } else {
    return false;
  }

  //return localStorage.getItem("access-token");
}
