import axios from "axios";
// import { toast } from 'react-toastify';
import { getAuthHeader } from "utils/auth";
// import { errorToast, ServerError, SystemError } from '../../utils/utils';

/** all dao api function start */

export const getTransactionAPI = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/v1/transactions/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const getTransactionsAPI = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "/v1/transactions?page=" +
        id +
        "&limit=10&sortBy=createdAt:desc",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const getTransactionsDashboardAPI = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "/v1/transactions?page=1&limit=4&sortBy=createdAt:desc",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const getTransactionsProjectIdAPI = async (id, name) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "/v1/transactions/project/" +
        id +
        "?page=" +
        name +
        "&limit=4&sortBy=createdAt:desc",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const createTransactionAPI = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/v1/transactions", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};
