// TODO Kaushal
// Make the response taking api call in the backend
// fix input arguments in p, np and r, to in pay , ...
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContractRead, useContractWrite, usePrepareContractWrite, useSigner, useNetwork, useSwitchNetwork } from 'wagmi';
// import ABI from './a.json';

// @mui material components
import Grid from '@mui/material/Grid';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import Box from '@mui/material/Box';
import ChartClients from './chart';
import BarChart from './barChart';
import TransactionDetails from './transactionDetails';
import SafetyStatus from './safetyStatus';
import UsageWarning from './usageWarning';
import './client.css';
import { useParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { getTx4ExecAPI, getHealth4Tx } from 'apis/execute';

function WriteNPButton({ calldata, callback }) {
	// console.log(typeof calldata.project.network.Id);

	let args_ = [];

	const { config } = usePrepareContractWrite({
		address: calldata.project.contract_address,
		chainId: parseInt(calldata.project.network.Id),
		abi: calldata.project.contract_object,
		functionName: calldata.name,
		args: calldata.inputs.length > 0 ? Object.values(calldata.inputs[0]) : [],
	});

	console.log(config);

	const { data: data, isLoading: isLoading, isSuccess, write } = useContractWrite(config);

	return (
		<>
			<SoftButton disabled={!write} color={'info'} onClick={() => write?.()}>
				Send Transaction
			</SoftButton>
			{/* {projectsTableData.contract.network.Id!=} */}
			{isLoading && <div>Check Wallet</div>}
			{isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
		</>
	);
}

function WritePButton({ calldata, callback }) {
	console.log(calldata);
	const conr = useContractWrite({
		mode: 'recklesslyUnprepared',
		address: calldata.project.contract_address,
		chainId: parseInt(calldata.project.network.Id),
		abi: calldata.project.contract_object,
		functionName: calldata.name,
		args: calldata.inputs.length > 0 ? Object.values(calldata.inputs[0]) : [],
		overrides: {
			from: '',
			value: calldata.pay,
			// ethers.utils.parseEther('0.01'),
		},
	});

	return (
		<>
			<SoftButton
				disabled={!conr.write}
				color={'info'}
				onClick={() => {
					console.log('click');
					conr.write?.();
				}}
			>
				Send Transaction
			</SoftButton>
			{conr.isLoading && <div>Check Wallet</div>}
			{conr.isSuccess && <div>Transaction: {JSON.stringify(conr.data)}</div>}
		</>
	);
}

function ReadButton({ calldata, callback }) {
	const contract = useContractRead({
		address: calldata?.project?.contract_address,
		abi: calldata?.project?.contract_object,
		functionName: calldata.name,
	});

	return (
		<>
			<SoftButton color={'info'} onClick={() => callback()}>
				Share Data
			</SoftButton>
		</>
	);
}

function Execute() {
	let { id } = useParams();
	const [txInfo, setTxInfo] = useState([]);
	const [healthInfo, setHealthInfo] = useState([]);
	const { data: signer, isError, isLoading } = useSigner();
	const { chain } = useNetwork();
	const { chains, error, isLoading: isLoadingExec, pendingChainId, switchNetwork } = useSwitchNetwork();

	const [isMobile, setIsMobile] = useState(false);

	React.useEffect(() => {
		if (window.innerWidth > 818 && window.innerWidth < 1214) {
			setIsMobile(true);
		} else if (window.innerWidth < 818) {
			setIsMobile(false);
		} else {
			setIsMobile(false);
		}
	}, window.innerWidth);

	useEffect(() => {
		console.log('chain is', chain);

		if (chain !== undefined) {
			if (txInfo.project !== undefined) {
				if (chain.id !== txInfo.project.network.Id) {
					console.log('inside');
					switchNetwork?.(parseInt(txInfo.project.network.Id));
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [txInfo, chain]);

	const fetchTxInfo = async () => {
		let data = await getTx4ExecAPI(id);
		setTxInfo(data.data);

		console.log('TxInfo: ', data.data);
	};

	useEffect(() => {
		fetchTxInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchHealthInfo = async (txInfo) => {
		let data = await getHealth4Tx({
			networkId: txInfo.project.network.Id,
			networkName: txInfo.project.network.name,
			contractAddress: txInfo.project.contract_address,
			method: txInfo.name,
			stateMutability: txInfo.stateMutability,
		});
		console.log('health', data);
		setHealthInfo(data.data[0]);
	};

	useEffect(() => {
		fetchHealthInfo(txInfo);
	}, [txInfo]);

	return (
		<SoftBox
			py={2}
			px={5}
			sx={{
				background: 'white',
				display: 'flex',
				'flex-direction': 'column',
				'align-items': 'center',
			}}
		>
			{/* <ReadButton /> */}
			<SoftBox
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<AppBar
						position="static"
						sx={{
							backgroundColor: '#F2F5FA',
							padding: '16px',
							display: 'flex',
							borderRadius: '16px',
							justifyContent: 'space-between',
							flexDirection: 'row',
						}}
					>
						<NavLink to="/">
							<img className="header-logo" src={require('../../assets/images/header-logo.png')} alt="headerLogo" />
						</NavLink>
						<ConnectButton />
					</AppBar>
				</Box>
			</SoftBox>
			<div className="main-container">
				<Grid container direction="row" spacing={6} py={3} justifyContent="space-evenly">
					<Grid
						item
						xs="auto"
						sm="auto"
						xl="auto"
						md="auto"
						lg="auto"
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<TransactionDetails />
					</Grid>
					<Grid
						item
						xs="auto"
						sm="auto"
						xl="auto"
						md="auto"
						lg="auto"
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<SafetyStatus tag={healthInfo?.tag} />
					</Grid>
					<Grid
						item
						xs="auto"
						sm="auto"
						xl="auto"
						md="auto"
						lg="auto"
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
						}}
						style={{ display: isMobile ? 'none' : null }}
					>
						<SoftBox
							sx={{
								backgroundColor: '#F2F5FA',
								'border-radius': '16px',
								padding: ' 1rem 1.5rem',
								'max-width': '350px',
								height: '100%',
							}}
						>
							<BarChart />
						</SoftBox>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={6} py={3} justifyContent="space-evenly">
					<Grid
						item
						xs="auto"
						sm="12"
						xl="4"
						md="4"
						lg="4"
						sx={{
							display: 'flex',
							'align- items': 'center',
							'justify-content': 'center',
						}}
					>
						<UsageWarning
							button={
								<div className="button-container">
									{txInfo.stateMutability === 'payable' ? (
										<WritePButton calldata={txInfo} />
									) : txInfo.stateMutability === 'nonpayable' ? (
										<WriteNPButton calldata={txInfo} />
									) : txInfo.stateMutability === 'view' ? (
										<ReadButton calldata={txInfo} />
									) : (
										<></>
									)}
								</div>
							}
						/>
					</Grid>
					<Grid
						item
						xs="auto"
						sm="12"
						xl="8"
						md="8"
						lg="8"
						sx={{
							display: 'flex',
							'align- items': 'center',
							'justify-content': 'center',
							width: '100%',
						}}
					>
						<SoftBox
							style={{
								'border-radius': '16px',
								width: '100%',
							}}
						>
							<div className="card-client">
								<ChartClients
									method_dist={healthInfo?.method_dist}
									abiObj={txInfo?.project?.contract_object === undefined ? false : txInfo?.project?.contract_object}
								/>
							</div>
						</SoftBox>
					</Grid>
				</Grid>
			</div>

			{/* <div className="button-container">
				{txInfo.stateMutability === 'payable' ? (
					<WritePButton calldata={txInfo} />
				) : txInfo.stateMutability === 'nonpayable' ? (
					<WriteNPButton calldata={txInfo} />
				) : txInfo.stateMutability === 'view' ? (
					<ReadButton calldata={txInfo} />
				) : (
					<></>
				)}
			</div> */}
		</SoftBox>
	);
}

export default Execute;
