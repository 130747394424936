import React, { useCallback, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "kaypee.eth",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "harsh.eth",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "jim.eth",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "getriff.eth",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "0xA45e...",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "bhk.eth",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="desc">Info</p>
      </div>
    );
  }

  return null;
};
export default function App() {
  return (
    <BarChart
      width={310}
      height={387}
      data={data}
      margin={{ top: 5, right: 10, left: 20, bottom: -10 }}
      layout="vertical"
      style={{
        "font-weight": "400",
        "font-size": "12px",
        "line-height": "18px",
      }}
    >
      <XAxis type="number" style={{ display: "none" }} />
      <YAxis type="category" dataKey="name" />
      {/* <Tooltip content={<CustomTooltip />} /> */}
      {/* <Legend /> */}
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <Bar dataKey="uv" barSize={6} fill="#1C1C1C;" />
    </BarChart>
  );
}
