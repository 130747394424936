import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Area,
} from "recharts";

const _data_example = [
  { name: "Page A", uv: 400 },
  { name: "Page B", uv: 800 },
  { name: "Page C", uv: 650 },
  { name: "Page D", uv: 200 },
  { name: "Page E", uv: 10 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{}}>
        <p className="label"> Page Name: {label}</p>
        <p className="label"> Value: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

function AnalyticsLineChart({ data }) {
  console.log("data is ", data);
  if (data === undefined) {
    data = _data_example;
  } else {
    data = _data_example;
  }
  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="0 0" />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#2152ff"
          fill="#2152ff55"
          strokeWidth={2}
        />

        {/* <Line strokeWidth={2} type="monotone" dataKey="uv" stroke="#2152ff" /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AnalyticsLineChart;
