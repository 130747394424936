import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui material components
import Grid from '@mui/material/Grid';
// import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
import Card from '@mui/material/Card';
// import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

import { createProjectAPI } from 'apis/project';

// Soft UI Dashboard React base styles
// import typography from 'assets/theme/base/typography';

// Dashboard layout components
// import BuildByDevelopers from 'pages/dashboard/components/BuildByDevelopers';
// import WorkWithTheRockets from 'pages/dashboard/components/WorkWithTheRockets';
// import Projects from 'pages/dashboard/components/Projects';
// import OrderOverview from 'pages/dashboard/components/OrderOverview';
// import ButtonStatisticsCard from 'pages/dashboard/components/ButtonStatisticsCard';
import SoftButton from 'components/SoftButton';
import { errorToast } from 'utils/utils';
import { successToast } from 'utils/utils';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

// Data
// import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
// import gradientLineChartData from 'layouts/dashboard/data/gradientLineChartData';

function CreateProject() {
	// const { size } = typography;
	// const { chart, items } = reportsBarChartData;

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [contractAddress, setContractAddress] = useState('');
	const [networkName, setNetworkName] = useState('');
	const [networkId, setNetworkId] = useState(0);
	const [txExpiryTime, setTxExpiryTime] = useState(30);
	const [tempContractObject, setTempContractObject] = useState('');
	const [contractObject, setContractObject] = useState({});
	const [contractObjectFlag, setContractObjectFlag] = useState(false);
	const [contractStatus, setContractStatus] = useState('check');
	const [formCheck, setFormCheck] = useState(false);

	const navigate = useNavigate();
	// const userId = useSelector((state) => state.userRef.userId);

	const createProject = async () => {
		if (
			name.length > 0 &&
			description.length > 0 &&
			contractAddress.length > 0 &&
			networkName.length > 0 &&
			txExpiryTime > 0 &&
			networkId > 0 &&
			contractObjectFlag &&
			JSON.stringify(contractObject) !== JSON.stringify({})
		) {
			let data = await createProjectAPI({
				name: name,
				description: description,
				contract_address: contractAddress,
				//TODO Change as fetcher function
				// creator: userId,
				network: { name: networkName, Id: networkId },
				contract_object: contractObject,
				transactionExpireTime: txExpiryTime,
			});
			if (data.status === 201) {
				setFormCheck(false);
				successToast('Project Created Successfully.');
				setTimeout(() => {
					navigate('/projects');
				}, 1000);
			} else {
				setFormCheck(true);
				errorToast(data.response.data.message);
				//show toasty for error;
			}
		} else {
			setFormCheck(true);
		}
	};

	function ABIObject(e) {
		try {
			if (JSON.parse(e.target.value)) {
				setContractObjectFlag(true);
				setContractObject(JSON.parse(e.target.value));
				setTempContractObject(JSON.parse(e.target.value));
			} else {
				setContractObjectFlag(false);
				setTempContractObject(e.target.value);
			}
		} catch {
			setContractObjectFlag(false);
			setTempContractObject(e.target.value);
		}
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox sx={{ width: 1 }}>
				<Grid container pb={3} direction="column" alignItems="left">
					<SoftTypography variant="h3">Create a project</SoftTypography>
				</Grid>
				<Grid sx={{ width: 1 }} container spacing={0}>
					<Card style={{ boxShadow: 'none' }} sx={{ width: 1 }}>
						<SoftBox p={3}>
							<SoftBox mb={-1}>
								<SoftTypography variant="h6" p={1} mb={-1}>
									Name of Project <span className="required-text">*</span>
								</SoftTypography>
								<SoftInput type="text" placeholder="ERC 20 Token" onChange={(e) => setName(e.target.value)} />
							</SoftBox>
							{name.length === 0 && formCheck ? (
								<SoftTypography variant="caption" color="error">
									Please add a valid Name of Project
								</SoftTypography>
							) : (
								<></>
							)}
							<SoftBox mb={-1} mt={2}>
								<SoftTypography variant="h6" p={1} mb={-1}>
									Description <span className="required-text">*</span>
								</SoftTypography>
								<SoftInput
									placeholder="This is project for interacting with an ERC ..."
									multiline
									rows={3}
									onChange={(e) => setDescription(e.target.value)}
								/>
							</SoftBox>
							{description.length === 0 && formCheck ? (
								<SoftTypography variant="caption" color="error">
									Please add Description
								</SoftTypography>
							) : (
								<></>
							)}
							<Grid container spacing={2} mt={0}>
								<Grid item xs={12} sm={6} xl={6}>
									<SoftBox display="flex" alignItems="end" gap="10px" mb={-1}>
										<SoftBox width="100%">
											<SoftTypography variant="h6" p={1} mb={-1}>
												Contract Address<span className="required-text">*</span>
											</SoftTypography>
											<SoftInput
												type="text"
												placeholder="0x2345..."
												onChange={(e) => {
													setContractAddress(e.target.value);
													setContractStatus('check');
												}}
												icon={{
													component:
														contractStatus === 'check' ? (
															'none'
														) : contractStatus === 'success' ? (
															<CheckCircleIcon color="success" />
														) : contractStatus === 'warning' ? (
															<WarningIcon sx={{ color: 'rgb(255 215 0)' }} />
														) : (
															'none'
														),
													direction: 'right',
												}}
											/>
										</SoftBox>
										<SoftBox>
											<SoftButton
												variant="gradient"
												color="info"
												onClick={() => {
													setContractStatus('warning');
												}}
											>
												Check
											</SoftButton>
										</SoftBox>
									</SoftBox>
									{contractAddress.length === 0 && formCheck ? (
										<SoftTypography variant="caption" color="error">
											Please add a valid contract address
										</SoftTypography>
									) : (
										<></>
									)}
								</Grid>
								<Grid item xs={12} sm={6} xl={6}>
									<SoftBox mb={-1}>
										<SoftTypography variant="h6" p={1} mb={-1}>
											Transaction Link Expiry Time in Minutes <span className="required-text">*</span>
										</SoftTypography>
										<SoftInput type="number" placeholder="30" onChange={(e) => setTxExpiryTime(e.target.value)} />
									</SoftBox>
									{txExpiryTime > 0 && formCheck ? (
										<SoftTypography variant="caption" color="error">
											Please add a valid expiry time
										</SoftTypography>
									) : (
										<></>
									)}
								</Grid>
								<Grid item xs={12} sm={6} xl={6}>
									<SoftBox mb={-1}>
										<SoftTypography variant="h6" p={1} mb={-1}>
											Network Name <span className="required-text">*</span>
										</SoftTypography>
										<SoftInput type="text" placeholder="Polygon Mainnet" onChange={(e) => setNetworkName(e.target.value)} />
									</SoftBox>
									{networkName.length === 0 && formCheck ? (
										<SoftTypography variant="caption" color="error">
											Please add a valid network name
										</SoftTypography>
									) : (
										<></>
									)}
								</Grid>
								<Grid item xs={12} sm={6} xl={6}>
									<SoftBox mb={-1}>
										<SoftTypography variant="h6" p={1} mb={-1}>
											Network ID <span className="required-text">*</span>
										</SoftTypography>
										<SoftInput type="number" placeholder="137" onChange={(e) => setNetworkId(e.target.value)} />
									</SoftBox>{' '}
									{networkId <= 0 && formCheck ? (
										<SoftTypography variant="caption" color="error">
											Please add a valid network id
										</SoftTypography>
									) : (
										<></>
									)}
								</Grid>
							</Grid>
							<SoftBox mb={-1} mt={2}>
								<SoftTypography variant="h6" p={1} mb={-1}>
									Contract ABI Object <span className="required-text">*</span>
								</SoftTypography>
								<SoftInput
									placeholder="{...}"
									multiline
									rows={7}
									value={contractObjectFlag ? JSON.stringify(contractObject, null, '\t') : tempContractObject}
									onChange={(e) => ABIObject(e)}
								/>
							</SoftBox>
							{!contractObjectFlag && JSON.stringify(contractObject) === JSON.stringify({}) && formCheck ? (
								<SoftTypography variant="caption" color="error">
									Please add a valid ABI object
								</SoftTypography>
							) : (
								<></>
							)}

							<Grid pt={3} container direction="row-reverse" justifyContent="right" alignItems="right">
								<SoftButton variant="gradient" color="info" onClick={createProject}>
									Create Project
								</SoftButton>
							</Grid>
						</SoftBox>
					</Card>
				</Grid>
			</SoftBox>
			<br />
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default CreateProject;
