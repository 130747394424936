/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard Materail-UI example components
import Table from "./components/Table";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";

import { postManConvert } from "./utils/gen_postman";
//import SoftPagination from "components/SoftPagination";

// Data
// import data from 'pages/dashboard/components/Magic/data';

function Magic({ magic, projectName }) {
  // const { rows } = data();
  // let columns = ['Name', 'Created At', 'Network Name', 'Contract Address'];
  const columns = [
    { Header: "Methods", name: "method", align: "left" },
    { Header: "Url", name: "url", align: "left" },
    { Header: "Inputs", name: "input", align: "left" },
    // { Header: 'Outputs', name: 'output', align: 'left' },
    // { name: 'address', align: 'left' },
    // { name: 'Created At', align: 'left' },
  ];

  // const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);

  const [tableState, setTableState] = useState("get");
  // const [rows, setRows] = useState([]);
  // const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  // const closeMenu = () => setMenu(null);

  useEffect(() => {
    let rows_ = [];
    if (magic.length !== 0) {
      for (let i of magic) {
        let row = {};
        row.method = (
          <Grid container direction="row">
            <Grid px={1}>
              {i.method === "GET" ? (
                <Icon color="primary">ads_click</Icon>
              ) : (
                <Icon color="secondary">post_add</Icon>
              )}
            </Grid>
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {i.method}
            </SoftTypography>
          </Grid>
        );
        let inputStr = [];
        for (let e of i.inputs) {
          inputStr.push(e.name + ":" + e.type);
        }
        row.input = inputStr.toString();
        console.log(inputStr);
        row.url = i.url;
        // row.address = i.contract_address;
        if (tableState === "get" && i.method === "GET") {
          rows_.push(row);
        } else if (tableState === "post" && i.method === "POST") {
          rows_.push(row);
        } else {
        }
      }
    }

    setRows(rows_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magic, tableState]);

  const downloadFile = () => {
    const myData = postManConvert(magic, projectName); // I am assuming that "this.state.myData"
    // is an object and I wrote it to file as
    // json

    // create file in browser
    const fileName = projectName + ".postman_collection";
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <Card style={{ boxShadow: "none" }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pb={0}
      >
        <SoftBox>
          <SoftTypography variant="h6" gutterBottom>
            Magic
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap="10px"
        p={3}
        pt={2}
      >
        <SoftBox display="flex" gap="10px" flexWrap="wrap" alignItems="center">
          <SoftBadge
            badgeContent="Get"
            size="lg"
            container
            onClick={() => {
              setTableState("get");
            }}
            className="cursor-pointer"
            variant={tableState === "get" ? "gradient" : "contained"}
          />
          <SoftBadge
            badgeContent="Post"
            size="lg"
            onClick={() => {
              setTableState("post");
            }}
            className="cursor-pointer"
            variant={tableState === "post" ? "gradient" : "contained"}
            container
          />
        </SoftBox>
        <SoftBox display="flex" gap="10px" alignItems="center" flexWrap="wrap">
          <SoftButton variant="gradient" color="info" onClick={downloadFile}>
            Download Postman JSON
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
      </SoftBox>
      {/* <SoftPagination>
        <SoftPagination item>
          <Icon>keyboard_arrow_left</Icon>
        </SoftPagination>
        <SoftPagination item active>
          1
        </SoftPagination>
        <SoftPagination item>2</SoftPagination>
        <SoftPagination item>3</SoftPagination>
        <SoftPagination item>
          <Icon>keyboard_arrow_right</Icon>
        </SoftPagination>
      </SoftPagination> */}
    </Card>
  );
}

export default Magic;
