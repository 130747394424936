import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom"
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
// import Switch from '@mui/material/Switch';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useDispatch } from "react-redux";

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";
// import IllustrationLayout from '../components/IllustrationLayout';

import { loginAPI } from "apis/auth";
// Images
import curved9 from "assets/images/curved-images/landing-page.jpg";
import { ACCESS_TOKEN } from "redux/action/index.action";
import { REFRESH_TOKEN } from "redux/action/index.action";
import { ACCESS_TOKEN_EXPIRES } from "redux/action/index.action";
import { REFRESH_TOKEN_EXPIRES } from "redux/action/index.action";
import { USER_ID } from "redux/action/index.action";
import { USER_NAME } from "redux/action/index.action";
//import { ERROR_MESSAGE } from "redux/action/index.action";
import { errorToast } from "utils/utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getAuthHeader } from "utils/auth";

function SignIn() {
  // const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  //   const accessToken = useSelector((state) => state.authRef.ACCESS_TOKEN);

  const dispatch = useDispatch();
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login = async () => {
    setCheck(true);
    if (email.length > 0 && password.length > 0) {
      let data = await loginAPI({ email, password });
      if (data?.status === 200) {
        setCheck(false);
        dispatch({
          type: ACCESS_TOKEN,
          payload: data.data.tokens.access.token,
        });
        dispatch({
          type: ACCESS_TOKEN_EXPIRES,
          payload: data.data.tokens.access.expires,
        });
        dispatch({
          type: REFRESH_TOKEN,
          payload: data.data.tokens.refresh.token,
        });
        dispatch({
          type: REFRESH_TOKEN_EXPIRES,
          payload: data.data.tokens.refresh.expires,
        });
        dispatch({ type: USER_ID, payload: data.data.user.id });
        dispatch({ type: USER_NAME, payload: data.data.user.name });

        //   localStorage.setItem("access-token", data.data.tokens.access.token);
        //   localStorage.setItem(
        //     "access-token-expires",
        //     data.data.tokens.access.expires
        //   );
        //   localStorage.setItem("refresh-token", data.data.tokens.refresh.token);
        //   localStorage.setItem(
        //     "refresh-token-expires",
        //     data.data.tokens.refresh.expires
        //   );
        //   localStorage.setItem("user-id", data.data.user.id);
        //   localStorage.setItem("user-name", data.data.user.name);
        navigate("/dashboard");
      } else {
        errorToast(data.response.data.message);
        // dispatch({
        //   type: ERROR_TYPE,
        //   payload: "error",
        // });
        // dispatch({
        //   type: ERROR_MESSAGE,
        //   payload: data.response.data.message,
        // });
        //show toasty for error;
      }
    }
    // localStorage.setItem('expiry',data.data.token)
  };

  useEffect(() => {
    let check = getAuthHeader();

    if (check) {
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={-0.5} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={
              email.length > 0 || check
                ? String(email)
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                  ? false
                  : true
                : false
            }
          />
          {email.length > 0 || check ? (
            String(email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ) ? (
              <></>
            ) : (
              <SoftTypography variant="caption" color="error">
                Please add a valid Email
              </SoftTypography>
            )
          ) : (
            <></>
          )}
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={-0.5} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type={passwordShow ? "text" : "password"}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={!check || password.length > 0 ? false : true}
            icon={{
              component: (
                <>
                  {passwordShow ? (
                    <RemoveRedEyeIcon
                      onClick={() => {
                        setPasswordShow(false);
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => {
                        setPasswordShow(true);
                      }}
                    />
                  )}
                </>
              ),
              direction: "right",
            }}
          />
          {!check || password.length > 0 ? (
            <></>
          ) : (
            <SoftTypography variant="caption" color="error">
              Please add a valid Password
            </SoftTypography>
          )}
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
					<Switch checked={rememberMe} onChange={handleSetRememberMe} />
					<SoftTypography
						variant="button"
						fontWeight="regular"
						onClick={handleSetRememberMe}
						sx={{ cursor: 'pointer', userSelect: 'none' }}
					>
						&nbsp;&nbsp;Remember me
					</SoftTypography>
				</SoftBox> */}
        <SoftBox textAlign="right" mt={-2}>
          <SoftTypography
            color="info"
            fontWeight="regular"
            component={Link}
            to="/authentication/forgot-password"
            textGradient
            variant="button"
          >
            Forgot your password?{" "}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth onClick={login}>
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {/* <SoftAlert color="error" dismissible>
				This is a dismissible alert!
			</SoftAlert> */}
    </CoverLayout>
  );
}

export default SignIn;
