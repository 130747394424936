import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Area,
  AreaChart,
  Tooltip,
} from "recharts";

const _data = [
  { name: "Page A", uv: 400 },
  { name: "Page B", uv: 600 },
  { name: "Page C", uv: 800 },
  { name: "Page D", uv: 200 },
  { name: "Page E", uv: 100 },
  { name: "Page F", uv: 900 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{}}>
        <p className="label"> Page Name: {label}</p>
        <p className="label"> Value: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

function HistoricalChart({ data }) {
  if (data === undefined) {
    data = _data;
  } else {
    data = _data;
  }
  return (
    <ResponsiveContainer width="100%" height={372}>
      <AreaChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#2152ff"
          fill="#2152ff55"
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default HistoricalChart;
