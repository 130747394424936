import { combineReducers } from "redux";
import { authRef } from "./authRedux/reducer.authRedux";
import { userRef } from "./userRedux/reducer.userRedux";

const rootReducer = combineReducers({
  authRef: authRef,
  userRef: userRef,
});

export default rootReducer;
