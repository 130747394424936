/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";
import NODATA from "../../../../assets/images/noData.svg";
//import SoftPagination from "components/SoftPagination";

// Data
// import data from 'pages/dashboard/components/Projects/data';

function Projects({ projects, totalPage, pageNumber, setPageNumber }) {
  const columns = [
    { Header: "Name", name: "name", align: "left" },
    { Header: "Network", name: "network", align: "center" },
    // { Header: "Network Id", name: "id", align: "center" },
    { Header: "Contract Address", name: "contract", align: "center" },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    let rows_ = [];
    if (projects.length !== 0) {
      for (let i of projects) {
        let row = {};
        row.name = (
          <Grid container direction="row" style={{ alignItems: "center" }}>
            <Grid px={1}>
              <Icon color="info">description</Icon>
            </Grid>
            <SoftTypography variant="h6" color="text" fontWeight="medium">
              {i.name}
            </SoftTypography>
          </Grid>
        );
        row.network = i.network.name;
        row.id = i.network.Id;
        row.contract = (
          <Grid container direction="row">
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {i.contract_address.substring(0, 4)}...
              {i.contract_address.substring(
                i.contract_address.length - 4,
                i.contract_address.length
              )}
            </SoftTypography>
            <Grid px={1}>
              <div className="tooltip">
                <Icon
                  color="info"
                  onClick={() => {
                    navigator.clipboard.writeText(i.contract_address);
                  }}
                >
                  copy
                </Icon>
                <span className="tooltiptext"> Copy Contract Address </span>
              </div>
            </Grid>
          </Grid>
        );
        rows_.push(row);
      }
    }

    setRows(rows_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return (
    <Card style={{ boxShadow: "none" }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <SoftBox>
          <SoftTypography variant="h6" gutterBottom>
            Projects
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {totalPage > 0 ? (
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table
            columns={columns}
            rows={rows}
            totalPage={totalPage}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </SoftBox>
      ) : (
        <>
          <img
            src={NODATA}
            alt=""
            className=""
            height="300"
            style={{ paddingBottom: "30px" }}
          />
        </>
      )}
    </Card>
  );
}

export default Projects;
