import { useState, useEffect } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'examples/Footer';

import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard';
// import PlaceholderCard from 'examples/Cards/PlaceholderCard';

// Overview page components
//import Header from "pages/projects/components/Header";

import SyncIcon from '@mui/icons-material/Sync';
import { getProjectsAPI } from 'apis/project';

// Images
import img1 from 'assets/images/curved-images/curved1.jpg';
import img2 from 'assets/images/curved-images/curved14.jpg';
// import img3 from 'assets/images/curved-images/curved0.jpg';
import img4 from 'assets/images/curved-images/curved-6.jpg';
import img5 from 'assets/images/curved-images/landing-page.jpg';
import img6 from 'assets/images/curved-images/white-curved.jpeg';
import img7 from 'assets/images/curved-images/signin.jpg';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftButton from 'components/SoftButton';

// import team1 from 'assets/images/team-1.jpg';
// import team2 from 'assets/images/team-2.jpg';
// import team3 from 'assets/images/team-3.jpg';
// import team4 from 'assets/images/team-4.jpg';

function Overview() {
	const [projects, setProjects] = useState([]);

	let imgarr = [img1, img2, img4, img5, img6, img7];

	const fetchProjects = async () => {
		let data = await getProjectsAPI();
		setProjects(data.data.results);
	};

	function RefreshAction() {
		fetchProjects();
	}

	useEffect(() => {
		fetchProjects();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox display="flex" justifyContent="space-between" alignItems="end" gap="10px">
					<SoftTypography variant="h3">Projects</SoftTypography>
					<SoftButton
						variant="outlined"
						color="secondary"
						onClick={() => {
							RefreshAction();
						}}
					>
						<SyncIcon />
						&nbsp; Refresh
					</SoftButton>
				</SoftBox>
			</SoftBox>
			<SoftBox mb={3}>
				<Card style={{ boxShadow: 'none' }}>
					{/* <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                Projects
              </SoftTypography>
            </SoftBox>
            {/* <SoftBox mb={1}>
							<SoftTypography variant="button" fontWeight="regular" color="text">
								
							</SoftTypography>
						</SoftBox>
          </SoftBox> */}
					<SoftBox p={4}>
						<Grid container spacing={3}>
							{projects.map((project) => {
								return (
									<Grid item xs={12} md={6} xl={3}>
										<DefaultProjectCard
											image={imgarr[Math.floor(Math.random() * 5)]}
											// label="project #2"
											title={project.name}
											// description={project.description}
											action={{
												type: 'internal',
												route: '/projects/' + project.id,
												color: 'info',
												label: 'view project',
											}}
										/>
									</Grid>
								);
							})}

							{/* <Grid item xs={12} md={6} xl={3}>
								<PlaceholderCard title={{ variant: 'h5', text: 'New project' }} outlined />
							</Grid> */}
						</Grid>
					</SoftBox>
				</Card>
			</SoftBox>
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default Overview;
