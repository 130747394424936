/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";

function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
}) {
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <SoftAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      style={{ boxShadow: "none" }}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        height: "100%",
      }}
    >
      <SoftBox
        style={{ boxShadow: "none" }}
        position="relative"
        width="100.25%"
        shadow="xl"
        borderRadius="xl"
        sx={{
          backgroundColor: "#F2F5FA",
          height: "100%",
        }}
      >
        <SoftBox
          p={2}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <SoftBox>
              {action.type === "internal" ? (
                <SoftTypography
                  component={Link}
                  to={action.route}
                  variant="h5"
                  textTransform="capitalize"
                >
                  {title}
                </SoftTypography>
              ) : (
                <SoftTypography
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  textTransform="capitalize"
                >
                  {title}
                </SoftTypography>
              )}
              <SoftTypography variant="body2">
                Network Name: Polygon{" "}
              </SoftTypography>
            </SoftBox>
            <div className="project-card-icon-container">
              <img src={image} alt="" className="" />
            </div>
          </SoftBox>
          <SoftBox
            display="flex"
            //  justifyContent="flex-end"
            alignItems="center"
            mt={1}
          >
            <SoftTypography variant="body2" color="info">
              Total Transactions: 1000
            </SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              backgroundColor: "#e9ecef",
              height: "5px",
              borderRadius: "20px",
              margin: "5px 0px 10px 0px",
            }}
          ></SoftBox>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {action.type === "internal" ? (
              <SoftButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </SoftButton>
            ) : (
              <SoftButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </SoftButton>
            )}
            <SoftBox display="flex">{renderAuthors}</SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {/* <SoftBox pt={3} px={0.5}> */}
      {/* <SoftBox mb={1}>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            textGradient
          >
            {label}
          </SoftTypography>
        </SoftBox> */}

      {/* <SoftBox mb={3} lineHeight={0}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox> */}
      {/* <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {action.type === "internal" ? (
            <SoftButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </SoftButton>
          ) : (
            <SoftButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </SoftButton>
          )}
          <SoftBox display="flex">{renderAuthors}</SoftBox>
        </SoftBox> */}
      {/* </SoftBox> */}
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
