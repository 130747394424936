import axios from 'axios';
// import { toast } from 'react-toastify';
import { getAuthHeader } from 'utils/auth';

export const getAllFunctionsAnalyticsAPI = async () => {
	let token = await getAuthHeader();

	const result = await axios
		.get(process.env.REACT_APP_API_URL + '/v1/analytics/globalfunctions', {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => {
			return res;
		})
		.catch(function (error) {
			return false;
		});

	return result;
};

export const getProjectFunctionsAnalyticsAPI = async (id) => {
	let token = await getAuthHeader();

	const result = await axios
		.get(process.env.REACT_APP_API_URL + '/v1/analytics/project/functions?projectId=' + id, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => {
			return res;
		})
		.catch(function (error) {
			return false;
		});

	return result;
};

export const getHistoricalTransactionsAnalyticsAPI = async (length) => {
	let token = await getAuthHeader();

	const result = await axios
		.get(process.env.REACT_APP_API_URL + '/v1/analytics/dailytxns?length=' + length, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => {
			return res;
		})
		.catch(function (error) {
			return false;
		});

	return result;
};
