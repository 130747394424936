import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRES,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRES,
} from "../../action/index.action";

const initialState = {
  accessToken: "",
  accessTokenExpires: "",
  refreshToken: "",
  refreshTokenExpires: "",
};

export const authRef = (state = initialState, action) => {
  switch (action.type) {
    case ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };
    case ACCESS_TOKEN_EXPIRES:
      return { ...state, accessTokenExpires: action.payload };
    case REFRESH_TOKEN_EXPIRES:
      return { ...state, refreshTokenExpires: action.payload };
    default:
      return state;
  }
};
