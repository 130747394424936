import axios from "axios";
// import { toast } from 'react-toastify';
import { getAuthHeader } from "utils/auth";
// import { errorToast, ServerError, SystemError } from '../../utils/utils';

/** all dao api function start */

export const getProjectAPI = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/v1/projects/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const getProjectsAPI = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/v1/projects", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const getProjectsTableAPI = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL + "/v1/projects?page=" + id + "&limit=10",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

export const createProjectAPI = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/v1/projects", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });

  return result;
};

export const getProjectMagicAPI = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "/v1/projects/magic/" +
        id +
        "?page=" +
        1 +
        "&limit=1",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};
