import { USER_ID, USER_NAME } from "../../action/index.action";

const initialState = {
  userId: "",
  userName: "",
};

export const userRef = (state = initialState, action) => {
  switch (action.type) {
    case USER_ID:
      return { ...state, userId: action.payload };
    case USER_NAME:
      return { ...state, userName: action.payload };
    default:
      return state;
  }
};
