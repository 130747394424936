import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui material components
import Grid from '@mui/material/Grid';
// import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
import Card from '@mui/material/Card';
// import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

import { createProjectAPI } from 'apis/project';

// Soft UI Dashboard React base styles
// import typography from 'assets/theme/base/typography';

// Dashboard layout components
// import BuildByDevelopers from 'pages/dashboard/components/BuildByDevelopers';
// import WorkWithTheRockets from 'pages/dashboard/components/WorkWithTheRockets';
// import Projects from 'pages/dashboard/components/Projects';
// import OrderOverview from 'pages/dashboard/components/OrderOverview';
// import ButtonStatisticsCard from 'pages/dashboard/components/ButtonStatisticsCard';
import SoftButton from 'components/SoftButton';
import { errorToast } from 'utils/utils';
import { successToast } from 'utils/utils';
import { useSelector } from 'react-redux';

// Data
// import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
// import gradientLineChartData from 'layouts/dashboard/data/gradientLineChartData';

function Profile() {
	// const { size } = typography;
	// const { chart, items } = reportsBarChartData;

	// const userId = useSelector((state) => state.userRef.userId);

	const token = useSelector((state) => state);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox sx={{ width: 1 }}>
				<Grid container pb={3} direction="column" alignItems="left">
					<SoftTypography variant="h3">Profile</SoftTypography>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={6}>
						<Card
							style={{ boxShadow: 'none' }}
							sx={{
								padding: '64px',
							}}
						>
							<div
								className=""
								style={{
									display: 'flex',
									gap: '16px',
									alignItems: 'center',
									flexDirection: 'column',
								}}
							>
								<SoftBox
									sx={{
										height: '215px',
										display: 'flex',
										alignItems: 'center',
										width: 'fit-content',
									}}
								>
									<img src={require('../../assets/images/Fifi.jpg')} alt="" className="profile-image" />
								</SoftBox>
								<SoftBox
									sx={{
										display: 'flex',
										'flex-direction': 'column',
										alignItems: 'center',
										gap: '16px',
										width: 'fit-content',
									}}
								>
									<SoftBox sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
										<SoftTypography variant="h5">Name</SoftTypography>
										<SoftInput
											type="text"
											placeholder="Name"
											defaultValue=" Jhon Dau "
											//onChange={(e) => setName(e.target.value)}
										/>
									</SoftBox>
									<SoftBox sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
										<SoftTypography variant="h5">Email</SoftTypography>
										<SoftInput
											type="email"
											placeholder="E-mail"
											defaultValue=" JhonDau@email.com "
											//onChange={(e) => setName(e.target.value)}
										/>
									</SoftBox>

									<SoftBox mt={3}>
										<SoftButton
											variant="gradient"
											color="info"
											fullWidth
											size="large"
											circular={false}
											//  onClick={createProject}
										>
											Save
										</SoftButton>
									</SoftBox>
								</SoftBox>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} md={6} lg={6}>
						<Card
							style={{ boxShadow: 'none' }}
							sx={{
								padding: '20px',
							}}
						>
							<SoftTypography variant="h5"> Api Token </SoftTypography>
							<div
								className=""
								style={{
									background: '#eee',
									padding: '5px 10px',
									display: 'flex',
									gap: '10px',
									alignItems: 'center',
									borderRadius: '20px',
									marginTop: '10px',
								}}
							>
								{/* 12532647324...328749832473 */}
								{token?.authRef?.accessToken ? (
									<>
										{token.authRef.accessToken.substring(0, 4)}...
										{token.authRef.accessToken.substring(
											token.authRef.accessToken.length - 4,
											token.authRef.accessToken.length
										)}{' '}
									</>
								) : (
									''
								)}
								<ContentCopyIcon
									style={{ cursor: 'pointer' }}
									onClick={() => {
										try {
											let temp = token?.authRef?.accessToken || '';
											navigator.clipboard.writeText(temp);
											alert('Content copied to clipboard');
										} catch (err) {
											console.error('Failed to copy: ', err);
										}
									}}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			</SoftBox>
			<br />
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default Profile;
