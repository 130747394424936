import { useState, useEffect } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftBadge from 'components/SoftBadge';

// Soft UI Dashboard React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Table from 'examples/Tables/Transaction';

import { getTransactionsAPI } from 'apis/transaction';
import SoftButton from 'components/SoftButton';
import SyncIcon from '@mui/icons-material/Sync';
import moment from 'moment';

//import SoftPagination from "components/SoftPagination";

// Data
// import authorsTableData from 'pages/transactions/data/authorsTableData';
// import projectsTableData from 'pages/transactions/data/projectsTableData';

function Tables() {
	const columns = [
		{ Header: 'Name', name: 'name', align: 'left' },
		{ Header: 'Project', name: 'project', align: 'left' },
		{ Header: 'Status', name: 'status', align: 'center' },
		{ Header: 'Expiry', name: 'expiry', align: 'left' },
		{ Header: 'Method', name: 'method', align: 'left' },
	];

	const [rows, setRows] = useState([]);
	const [transactions, setTransactions] = useState([]);

	const [tableState, setTableState] = useState('all');

	const [pageNumber, setPageNumber] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	const fetchTransactions = async () => {
		let data = await getTransactionsAPI(pageNumber);
		setTransactions(data.data.results);
		setTotalPage(data.data.totalPages);
	};

	useEffect(() => {
		fetchTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	function RefreshAction() {
		setPageNumber(1);
		fetchTransactions();
	}

	// useEffect(() => {
	//   let rows_ = [];
	//   if (transactions.length !== 0) {
	//     for (let i of transactions) {
	//       let row = {};
	//       row.name = (
	//         <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
	//           <SoftBox mr={2}>
	//             <Icon>
	//               {i.status === "PENDING"
	//                 ? "pending"
	//                 : i.status === "STARTED"
	//                 ? "start"
	//                 : i.status === "EXECUTED"
	//                 ? "check_circle"
	//                 : "error"}
	//             </Icon>
	//           </SoftBox>
	//           <SoftBox display="flex" flexDirection="column">
	//             <SoftTypography variant="button" fontWeight="medium">
	//               {i.name}
	//             </SoftTypography>
	//           </SoftBox>
	//         </SoftBox>
	//       );
	//       row.status = (
	//         <SoftBadge
	//           variant="gradient"
	//           badgeContent={i.status}
	//           color={
	//             i.status === "PENDING"
	//               ? "info"
	//               : i.status === "STARTED"
	//               ? "warning"
	//               : i.status === "EXECUTED"
	//               ? "success"
	//               : "error"
	//           }
	//           size="xs"
	//           container
	//         />
	//       );
	//       row.project = (
	//         <SoftBox display="flex" flexDirection="column">
	//           <SoftTypography variant="caption" fontWeight="medium" color="text">
	//             {i.project.name}
	//           </SoftTypography>
	//         </SoftBox>
	//       );
	//       row.expiry = moment(i.expireAt).format("LLL");
	//       row.method = (
	//         <Grid container direction="row">
	//           <Grid px={1}>
	//             {i.method === "GET" ? (
	//               <Icon color="primary">ads_click</Icon>
	//             ) : (
	//               <Icon color="secondary">post_add</Icon>
	//             )}
	//           </Grid>
	//           <SoftTypography variant="caption" color="text" fontWeight="medium">
	//             {i.method}
	//           </SoftTypography>
	//         </Grid>
	//       );
	//       // row.url = i.url;
	//       // row.address = i.contract_address;
	//       rows_.push(row);
	//     }
	//   }

	//   setRows(rows_);
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [transactions]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pb={3}>
				<SoftBox display="flex" justifyContent="space-between" alignItems="end" gap="10px">
					<SoftTypography variant="h3">Transactions for all projects</SoftTypography>{' '}
					<SoftButton
						variant="outlined"
						color="secondary"
						onClick={() => {
							RefreshAction();
						}}
					>
						<SyncIcon />
						&nbsp; Refresh
					</SoftButton>
				</SoftBox>
			</SoftBox>
			<SoftBox pb={3}>
				<SoftBox mb={3}>
					<Card style={{ boxShadow: 'none' }}>
						<SoftBox display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap" gap="10px" p={3} pt={2}>
							<SoftBox display="none" gap="10px" alignItems="center" flexWrap="wrap">
								<SoftTypography variant="h6">Date/Time Filter</SoftTypography>
								<SoftBadge
									badgeContent="All"
									size="md"
									container
									onClick={() => {
										setTableState('all');
									}}
									className="cursor-pointer"
									variant={tableState === 'all' ? 'gradient' : 'contained'}
								/>
								<SoftBadge
									badgeContent="Last 24 Hours"
									size="md"
									onClick={() => {
										setTableState('24Hours');
									}}
									className="cursor-pointer"
									variant={tableState === '24Hours' ? 'gradient' : 'contained'}
									container
								/>{' '}
								<SoftBadge
									badgeContent="Last 8 Days"
									size="md"
									onClick={() => {
										setTableState('8Days');
									}}
									className="cursor-pointer"
									variant={tableState === '8Days' ? 'gradient' : 'contained'}
									container
								/>{' '}
								<SoftBadge
									badgeContent="Last 30 Days"
									size="md"
									onClick={() => {
										setTableState('30Days');
									}}
									className="cursor-pointer"
									variant={tableState === '30Days' ? 'gradient' : 'contained'}
									container
								/>
							</SoftBox>
							<SoftBox display="flex" gap="10px" alignItems="center" flexWrap="wrap">
								{/* <SoftButton
                  variant="gradient"
                  color="info"
                  //  onClick={createProject}
                >
                  Download CSV
                </SoftButton> */}
								<SoftButton
									variant="gradient"
									color="info"
									//    onClick={createProject}
								>
									Download Postman JSON
								</SoftButton>
							</SoftBox>
						</SoftBox>

						<SoftBox
							sx={{
								'& .MuiTableRow-root:not(:last-child)': {
									'& td': {
										borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
									},
								},
							}}
						>
							<Table
								columns={columns}
								rows={transactions}
								totalPage={totalPage}
								pageNumber={pageNumber}
								setPageNumber={setPageNumber}
							/>
						</SoftBox>
						{/* <SoftPagination>
              <SoftPagination item>
                <Icon>keyboard_arrow_left</Icon>
              </SoftPagination>
              <SoftPagination item active>
                1
              </SoftPagination>
              <SoftPagination item>2</SoftPagination>
              <SoftPagination item>3</SoftPagination>
              <SoftPagination item>
                <Icon>keyboard_arrow_right</Icon>
              </SoftPagination>
            </SoftPagination> */}
					</Card>
				</SoftBox>
				{/* <Card>
					<SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
						<SoftTypography variant="h6">Projects list</SoftTypography>
					</SoftBox>
					<SoftBox
						sx={{
							'& .MuiTableRow-root:not(:last-child)': {
								'& td': {
									borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
								},
							},
						}}
					>
						<Table columns={prCols} rows={prRows} />
					</SoftBox>
				</Card> */}
			</SoftBox>
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</DashboardLayout>
	);
}

export default Tables;
