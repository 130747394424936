import { Card, Grid } from "@mui/material";
import AnalyticsLineChart from "components/Charts/AnalyticsLineChart";
import AnalyticsPieChart from "components/Charts/AnalyticsPieChart";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import SoftButton from "components/SoftButton";
import {
  getAllFunctionsAnalyticsAPI,
  getHistoricalTransactionsAnalyticsAPI,
} from "apis/analytics";
import VariableLineChart from "components/Charts/VariableLineChart";
import VariableBarChart from "components/Charts/VariableBarChart";

function Analytics() {
  const [allFunctions, SetAllFunctions] = useState([]);
  const [allFunctionsValue, SetAllFunctionsVal] = useState(0);
  const [historicalData, SetHistoricalData] = useState([]);

  const fetchAllFunctions = async () => {
    let data = await getAllFunctionsAnalyticsAPI();
    console.log(data.data);
    let pieChartData = [];
    for (let i = 0; i < 3; i++) {
      pieChartData.push({
        name: data.data.txns[i].functionName,
        value: data.data.txns[i].count,
      });
    }
    pieChartData.push({
      name: "Others",
      value: data.data.otherCount,
    });

    SetAllFunctions(pieChartData);
    SetAllFunctionsVal(data.data.totalCount);
    // for (){
    //   pieChartData.push
    // }
    // setProjects(data.data.results);
  };

  const fetchHistoricalData = async (length) => {
    let data = await getHistoricalTransactionsAnalyticsAPI(length);
    console.log(data);
    let hisArr = [];
    const keys = Object.keys(data.data);
    for (let e of keys.reverse()) {
      hisArr.push({
        name: e,
        uv: data.data[e],
      });
    }
    SetHistoricalData(hisArr);
    // setProjects(data.data.results);
  };

  function RefreshAction() {}

  useEffect(() => {
    fetchAllFunctions();
    fetchHistoricalData(11);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          gap="10px"
        >
          <SoftTypography variant="h3">Analytics</SoftTypography>
          <SoftButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              RefreshAction();
            }}
          >
            <SyncIcon />
            &nbsp; Refresh
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} xl={6}>
            <Card style={{ boxShadow: "none", height: "100%" }}>
              <SoftBox p={3}>
                <SoftTypography variant="h4" mb={3}>
                  Most Transactors
                </SoftTypography>
                <Grid container spacing={2}>
                  {Array(4)
                    .fill()
                    .map(function (_v, _i) {
                      return (
                        <>
                          <Grid item xs={12} sm={12} xl={12}>
                            <Card style={{ boxShadow: "none" }}>
                              <SoftBox
                                p={2}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="10px"
                              >
                                <SoftTypography variant="h6">
                                  Under Construction
                                </SoftTypography>
                                <SoftTypography variant="h6">0</SoftTypography>
                              </SoftBox>
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} xl={6}>
            <Card style={{ boxShadow: "none", height: "100%" }}>
              <SoftBox p={3}>
                <SoftTypography variant="h4" mb={0}>
                  Frequently Called Function
                </SoftTypography>
                <AnalyticsPieChart />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Card style={{ boxShadow: "none" }}>
          <SoftBox p={3}>
            <SoftTypography variant="h4" mb={3}>
              Analytics Line Chart
            </SoftTypography>
            <AnalyticsLineChart data={historicalData} />
          </SoftBox>
        </Card>
      </SoftBox>
      <SoftBox mb={3}>
        <Card style={{ boxShadow: "none" }}>
          <Grid container>
            <Grid item xs={12} sm={12} xl={8}>
              <SoftBox p={3}>
                <SoftTypography variant="h4" mb={3}>
                  Gross Volume
                </SoftTypography>
                <VariableLineChart height={330} />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} xl={4}>
              <SoftBox class="border-left">
                <SoftBox
                  class="border-bottom"
                  style={{ paddingLeft: "10px", paddingTop: "10px" }}
                >
                  <SoftTypography variant="h4" mb={3}>
                    USD Balance
                  </SoftTypography>
                  <VariableLineChart height={150} />
                </SoftBox>
                <SoftBox style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                  <SoftTypography variant="h4" mb={3}>
                    Payouts
                  </SoftTypography>
                  <VariableLineChart height={150} />
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
      <SoftBox mb={3}>
        <Grid container>
          <Grid item xs={12} sm={12} xl={6}>
            <Card style={{ boxShadow: "none", height: "100%" }}>
              <SoftBox p={3}>
                <SoftTypography variant="h4" mb={3}>
                  Traffic Source
                </SoftTypography>
                <VariableBarChart height={300} />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Analytics;
