import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

// eslint-disable-next-line no-unused-vars
const _data_example = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
// const COLORS = ["#17c1e8", "#82d616", "#fbcf33", "#ea0606"];
const COLORS = ["#81c784", "#000", "#C6C7F8", "#95A4FC"];
function AnalyticsPieChart({ data, value }) {
  if (data === undefined) {
    data = _data_example;
  }
  if (value === undefined) {
    value = 0;
  }

  const [pieSelect, setPieSelect] = useState({
    name: "Total Transactions",
    value: value,
  });

  useEffect(() => {
    setPieSelect({
      name: "Total Transactions",
      value: value,
    });
  }, [value]);

  return (
    <>
      <ResponsiveContainer height={500}>
        <PieChart>
          <Pie
            data={data}
            cx={"50%"}
            cy={190}
            startAngle={360}
            endAngle={0}
            innerRadius={140}
            outerRadius={180}
            fill="transparent"
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                onMouseOver={() => {
                  setPieSelect(entry);
                }}
                onMouseOut={() => {
                  setPieSelect({
                    name: "Total Transactions",
                    value: value,
                  });
                }}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="pie-chart-text-container">
        <h4 className=""> {pieSelect.name} </h4>
        <h4 className=""> {pieSelect.value} </h4>
      </div>
      <div className="pie-chart-detail-container">
        {data.map((item, index) => (
          <div className="pie-chart-detail" key={index}>
            <SoftTypography variant="h6">{item.name}</SoftTypography>
            <div
              className="color-container"
              style={{ background: COLORS[index] }}
            ></div>
          </div>
        ))}
      </div>
    </>
  );
}

export default AnalyticsPieChart;
