/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';

// Soft UI Dashboard React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { Link } from 'react-router-dom';

// Authentication layout components
// import Footer from 'layouts/authentication/components/Footer';
import Footer from 'examples/Footer';

function CoverLayout({ color, header, title, description, information, token, image, top, children }) {
	return (
		<PageLayout background="white">
			<DefaultNavbar
				action={{
					type: 'external',
					route: 'https://www.notion.so/vaadai/Riff-Description-b55d1dc8b04b4909974b2a291a3e0b51',
					label: 'Learn More',
					color: 'dark',
				}}
			/>
			<Grid
				container
				justifyContent="center"
				sx={{
					minHeight: '96vh',
					margin: 0,
				}}
			>
				<Grid item xs={11} sm={6} md={5} xl={3}>
					<SoftBox mt={top}>
						<SoftBox pt={3} px={3}>
							{!header ? (
								<>
									<SoftBox mb={1}>
										<SoftTypography variant="h2" fontWeight="bold" color={color} textGradient>
											{title}
										</SoftTypography>
									</SoftBox>
									<SoftTypography variant="body2" fontWeight="regular" color="text">
										{description}
									</SoftTypography>
									<br />
									<SoftButton
										component={Link}
										to={token ? '/dashboard' : '/authentication/sign-in'}
										variant="gradient"
										color={color}
										key="sign-ip"
									>
										{' '}
										{token ? 'Dashboard' : 'Sign In'}
									</SoftButton>
									<br />
									{/* <SoftTypography py={3} variant="overline" fontWeight="regular" color="text">
										{information}
									</SoftTypography> */}
								</>
							) : (
								header
							)}
						</SoftBox>
						<SoftBox p={3}>{children}</SoftBox>
					</SoftBox>
				</Grid>
				<Grid item xs={12} md={5}>
					<SoftBox
						height="100%"
						display={{ xs: 'none', md: 'block' }}
						position="relative"
						right={{ md: '-6rem', xl: '-10rem' }}
						mr={-30}
						sx={{
							transform: 'skewX(-10deg)',
							overflow: 'hidden',
							borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
						}}
					>
						<SoftBox
							ml={-30}
							height="100%"
							sx={{
								backgroundImage: `url(${image})`,
								backgroundSize: 'cover',
								transform: 'skewX(10deg)',
							}}
						/>
					</SoftBox>
				</Grid>
			</Grid>
			{/* <Footer /> */}
			<Footer company={{ name: 'Riff', href: 'https://getriff.xyz' }} />
		</PageLayout>
	);
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
	header: '',
	title: '',
	description: '',
	color: 'info',
	top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
	header: PropTypes.node,
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string.isRequired,
	top: PropTypes.number,
	children: PropTypes.node.isRequired,
};

export default CoverLayout;
