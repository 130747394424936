import Landing from "./pages/landing";
import SignUp from "./pages/authentication/sign-up";
import SignIn from "./pages/authentication/sign-in";
import Dashboard from "pages/dashboard";
import Projects from "pages/projects";
import Project from "pages/projects/project";
import CreateProject from "pages/projects/create";
import Transactions from "pages/transactions";
import Execute from "pages/execute";
// Soft UI Dashboard React icons
//import Shop from "./examples/Icons/Shop";
//import Office from "./examples/Icons/Office";
// import Settings from './examples/Icons/Settings';
import Document from "./examples/Icons/Document";
import SpaceShip from "./examples/Icons/SpaceShip";
//import CustomerSupport from "./examples/Icons/CustomerSupport";
// import CreditCard from './examples/Icons/CreditCard';
import Cube from "./examples/Icons/Cube";
//import CreditCard from "examples/Icons/CreditCard";
import {
  SyncAlt,
  AccountTree,
  AddBusiness,
  Home,
  AutoGraph,
  Person2,
} from "@mui/icons-material";
import Demo from "./pages/demo/demo";
import Analytics from "pages/analytics";
import ForgotPassword from "pages/authentication/forgot-password";
import ChangePassword from "pages/authentication/change-password";
import Profile from "pages/profile";

//TODO: Create a New Project Page, View Project Page, Execution Page

const routes = [
  {
    type: "",
    name: "Landing",
    key: "landing",
    route: "landing",
    icon: <SpaceShip size="12px" />,
    component: <Landing />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "dashboard",
    icon: <Home size={"12px"} />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Create Project",
    key: "create",
    route: "create",
    icon: <AddBusiness size={"12px"} />,
    component: <CreateProject />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "projects",
    icon: <AccountTree size={"12px"} />,
    component: <Projects />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Project",
    key: "project",
    route: "projects/:id",
    icon: <AccountTree size={"12px"} />,
    component: <Project />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Execute",
    key: "execute",
    route: "execute/:id",
    icon: <Document size={"12px"} />,
    component: <Execute />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "transactions",
    icon: <SyncAlt size={"12px"} />,
    component: <Transactions />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route: "analytics",
    icon: <AutoGraph size={"12px"} />,
    component: <Analytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "profile",
    icon: <Person2 size={"12px"} />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "",
    name: "About",
    key: "about",
    route: "about",
    icon: <Cube size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Team",
    key: "team",
    route: "team",
    icon: <Cube size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },

  {
    type: "",
    name: "Sign Ip",
    key: "sign-ip",
    route: "/",
    icon: <Cube size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Forgot Password",
    key: "forgot-password",
    route: "authentication/forgot-password",
    icon: <Cube size="12px" />,
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Change Password",
    key: "change-password",
    route: "authentication/change-password",
    icon: <Cube size="12px" />,
    component: <ChangePassword />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    route: "authentication/sign-up",
    icon: <Cube size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  { route: "/demo", component: <Demo /> },
];

export default routes;
